import dbHelper from "../../../db/DBHelper";
import Battery from "../../../db/classes/battery";
import { Logging } from "../../../miConfig";

// Debug
const logIt = Logging.battery.STORE;

export default {
  namespaced: true,
  name: "battery",

  state: {
    batteries: new Map(),
    history: JSON.parse(localStorage.getItem("batt-history")) || [],
    listener: null,

    currentBattery: null,
    cycles: [],
    loading: {},
    errors: null
  },

  mutations: {
    SET_BATTERIES(state, batteries) {
      if (Array.isArray(batteries))
        state.batteries = new Map(
          batteries.map(battery => {
            if (!(battery instanceof Battery))
              battery = new Battery(battery)
            return [
              battery.id,
              battery
            ]
          })
        );
      else state.batteries = new Map(batteries)

      if (logIt) {
        console.log("Got batteries");
        console.table(Array.from(state.batteries.values()))
      }
    },

    SET_BATTERY(state, battery) {
      if (!(battery instanceof Battery)) battery = new Battery(battery);

      state.batteries.set(battery.id, battery)

      if (logIt) console.log("SET_BATTERY", battery);
    },

    SET_CURRENT_BATTERY(state, battery) {
      if (!(battery instanceof Battery)) battery = new Battery(battery);
      state.currentBattery = battery;

      if (logIt) console.log("SET_CURRENT_BATTERY", battery);
    },

    ADD_TO_HISTORY(state, battId) {
      const index = state.history.indexOf(battId);
      if (index !== -1) {
        state.history.splice(index, 1);
      }
      state.history.push(battId);
    },

    REMOVE_BATTERY_FROM_HISTORY(state, batteryId) {
      const index = state.history.indexOf(batteryId);
      if (index !== -1) {
        state.history.splice(index, 1);
      }
    },

    SET_BATTERY_CYCLES(state, { cycles, batteryID }) {
      if (batteryID) {
        const battery = state.batteries.get(batteryID)
        battery.setCycles(cycles)
        // state.currentBattery = battery
      }

      state.cycles = cycles

      if (logIt) console.log("SET_BATTERY_CYCLES", cycles);
    },

    RESET_STATE(state) {
      state.currentBattery = null;
      state.cycles = [];
      if (state.listener)
        state.listener()
      if (logIt) console.log("RESET_STATE");
    },

    SET_ERROR(state, error) {
      state.error = error
    }
  },

  actions: {
    async fetchBatteries({ commit }) {
      const batteries = await dbHelper.Battery.getBatteries();
      commit("SET_BATTERIES", batteries || []);
    },

    async fetchBatteryHistory({ commit }, batteryID) {
      let historyData = await dbHelper.Battery.getBatteryHistory(batteryID);
      if (!historyData?.length) {
        commit("SET_HISTORY", null);
        return console.warn("No History found for:", batteryID);
      }
    },

    async fetchBatteryCycles({ commit }, batteryID) {
      const batteryCycles = await dbHelper.Battery.getBatteryCycles(batteryID);

      // TODO Remove modes demo
      batteryCycles.forEach(cycle => {
        // Randomly generate two breakpoints between 0 and 100
        const break1 = Math.floor(Math.random() * 101);
        const break2 = Math.floor(Math.random() * (101 - break1)) + break1;

        // Calculate percentages based on breakpoints
        const perc0 = break1;
        const perc1 = break2 - break1;
        const perc2 = 100 - break2;

        cycle.modes = {
          0: perc0,
          1: perc1,
          2: perc2
        };
      });

      // To Session???
      commit("SET_BATTERY_CYCLES", { cycles: batteryCycles, batteryID })
    },

    // Current
    async setCurrentBatteryID({ state, getters, commit, dispatch }, batteryId) {
      if (state.listener)
        state.listener()

      if (!batteryId)
        console.warn(`Cannot set CurrentBattery with id:`, batteryId);

      let selectedBattery = getters.getBatteryByID(batteryId);
      commit("SET_CURRENT_BATTERY", selectedBattery);
      dispatch("addIdToHistory", batteryId)

      if (logIt) console.log("Start listenTo Battery", batteryId);
      state.listener = dbHelper.Battery.listenTo(batteryId,
        found => {
          commit("SET_CURRENT_BATTERY", found)
          if (logIt) console.log("Found listenTo Battery", found);
        },
        err => {
          commit("SET_ERROR", err)
          if (logIt) console.log("Error listenTo Battery", err);
        }
      )
    },

    addIdToHistory({ state, commit }, battID) {
      commit("ADD_TO_HISTORY", battID)
      localStorage.setItem("batt-history", JSON.stringify(state.history))
    },

    removeFromBatteryHistory({ commit }, batteryId) {
      commit('REMOVE_BATTERY_FROM_HISTORY', batteryId);
    },

    resetBatteryState({ commit, state }) {
      if (state.listener)
        state.listener()
      commit("RESET_STATE")
    }
  },

  getters: {
    getBatteries: (state) => state.batteries,
    getBatteryHistory: (state, getters) => {
      return state.history.map(it => getters.getBatteryByID(it)).reverse()
    },
    getBatteryByID: (state) => (id) => {
      if (logIt) console.log("Getting by ID", id, state.batteries);
      return state.batteries.get(id)
    },

    // Current
    getCurrentBattery: (state) => state.currentBattery,

    // Modes
    getModeCounts: (_, getters, __, rootGetters) => {
      const chargeModes = rootGetters[ "chargemode/getChargeModes" ];
      const modeCounts = Object.fromEntries(
        chargeModes.map(
          mode => [ mode.id, 0 ]
        )
      );
      getters.currentCycles.forEach(cycle => {
        if (cycle.initialMode || cycle.initialMode === 0) {
          modeCounts[ cycle.initialMode ]++;
        }
      });

      return modeCounts;
    },
    getModeCountsForModeID: (_, getters) => id => getters.getCurrentBattery?.modeCounts[ id ],
    hasModeCounts: (_, getters) => {
      const modeCounts = getters.getModeCounts;
      return Object.values(modeCounts).some(count => count > 0);
    },

    // Cycles
    currentCycles: (state) => state.cycles || [],
    currentCyclesCount: (state) => state.cycles?.length || 0,
    currentTotalTime: (_, getters) => getters.getCurrentBattery?.totalTime || 0,
  },
};
