import dbHelper from "../../db/DBHelper";

// errorModule.js
export default {
  namespaced: true,
  name: "error",

  state: {
    errors: [],
  },

  mutations: {
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },

    ADD_ERROR(state, { error, timestamp }) {
      state.errors.push({ error, timestamp });
    },

    CLEAR_ERRORS(state) {
      state.errors = [];
    },
  },

  actions: {
    async fetchErrors({ commit }) {
      const errors = await dbHelper.Charger.getDeviceErrors()

      // dbHelper.listenTo(
      //   (error) => {
      //     commit("ADD_ERROR", error)
      //   }
      // )
      if (errors)
        commit("SET_ERRORS", errors)
    },

    addError({ commit }, error) {
      commit("ADD_ERROR", { error, timestamp: Date.now() });
    },

    clearErrors({ commit }) {
      commit("CLEAR_ERRORS");
    },
  },

  getters: {
    getErrors: (state) => state.errors,

    // TODO error for...
    getErrorForCharger: (state) => (chargerId) =>
      state.error.find((it) => it.id == chargerId),
  },
};
