import axios from 'axios';
import config from '../../../../_res/config.json';
import store from '../store';

const server = config.server.default;

async function get(path, options) {
  try {
    let res = await axios.get(server + path, options);
    return res.data;
  } catch (err) {
    return null;
  }
}

async function post(path, data) {
  try {
    let res = await axios.post(server + path, data);
    return res.data;
  } catch (err) {
    return null;
  }
}

class LoginHandler {

  LOGIN_PATH = '/login';
  VERIFY_PATH = '/validate';
  USERS_PATH = '/users';

  userObject = {};
  userToken = {};
  userAuthenticated = false;

  constructor() {
    this.verifyToken();
  }

  async login(credentials, saveToken) {
    let response = await post(this.LOGIN_PATH, credentials);
    if (!response || response == null) return { success: false, error: 'Unbekannt' };
    if (response.error) return { success: false, error: response.error };
    if (!response.token) return { success: false, error: 'Unbekannt' };

    const { profile } = response.user;
    let initials = "";

    if (!profile.surname) {
      if (!profile.username) initials = "?";
      else initials = profile.username.substr(0, 1);
    } else {
      initials = response.user.profile.firstname.substr(0, 1);
    }

    this.userToken = response.token;
    this.userObject = response.user;
    this.userObject.initials = initials;

    store.state.userToken = this.userToken;
    store.state.userObject = this.userObject;

    if (saveToken) localStorage.setItem('auth_token', this.userToken);
    else localStorage.removeItem('auth_token');

    return { success: true }

  }

  logout() {
    this.userToken = undefined;
    this.userObject = undefined;
    this.userAuthenticated = false;

    store.state.userToken = this.userToken;
    store.state.userObject = this.userObject;

    localStorage.removeItem('auth_token');
  }

  async getUserData() {
    const response = await get('/user/@me/generateuserdata', {
      headers: {
        authorization: 'Bearer ' + this.userToken
      }
    });

    return response;

  }

  isLoggedIn() {
    return this.userAuthenticated;
  }

  isAdmin() {
    if (!this.userObject) return false;
    return this.userObject.authLevel > 0;
  }

  clientToken() {
    return `Bearer ${this.userToken}`;
  }

  async verifyToken() {
    this.userToken = localStorage.getItem('auth_token');
    if (!this.userToken) return false;

    const response = await get(this.VERIFY_PATH, {
      headers: {
        authorization: 'Bearer ' + this.userToken
      }
    });

    if (!response) return false;
    if (!response.authed) return false;
    if (!response.user) return false;

    let initials = response.user.email.substr(0, 1).toUpperCase();
    this.userObject = response.user;

    store.state.userToken = this.userToken;
    store.state.userObject = { ...this.userObject, initials };

    this.userAuthenticated = true;
    return true;

  }

}

const loginHandler = new LoginHandler();
export default loginHandler;
