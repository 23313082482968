export default {
  methods: {
    
    exportMessages() {
      // Convert JSON data to CSV format
      const csvData = this.convertToCSV(this.getNotifHistory());

      // Create a Blob with the CSV data
      const blob = new Blob([csvData], { type: "text/csv" });

      // Create a temporary URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a temporary anchor element to trigger the download
      const downloadLink = document.createElement("a");
      downloadLink.style.display = "none";
      downloadLink.href = url;
      downloadLink.download = "mcg-nfhist-" + Date.now() + ".csv";

      // Add the anchor element to the DOM and trigger the click event
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up by revoking the temporary URL
      window.URL.revokeObjectURL(url);
    },

    convertToCSV(data) {
      // Exclude the "icon" and "ts" fields from the headers
      const headers = Object.keys(data[0])
        .filter((key) => key !== "icon")
        .join(",") + "\n";

      // Convert each object in the array to a CSV row
      const rows = data
        .map((obj) => {
          // Parse the timestamp (ts) to a Date object
          const date = new Date(obj.ts);
          // Format the date as "dd.MM.yyyy, hh:mm:ss"
          const formattedDate = `${this.formatDate(date)} ${this.formatTime(date)}`;
          // Exclude the "icon" and "ts" fields from the row
          const { icon, ts, ...rest } = obj;
          icon, ts; 
          // Combine the formatted date and the remaining object values to create the row
          return [...Object.values(rest), formattedDate].join(",");
        })
        .join("\n");

      // Combine headers and rows to create the final CSV data
      return headers + rows;
    },
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
    formatTime(date) {
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    },

    clearMessages() {
      this.$store.commit('messages/clearMessages');
    },
    sendError(message) {
      this.$store.commit('messages/sendError', message);
    },
    sendWarning(message) {
      this.$store.commit('messages/sendWarning', message);
    },
    sendInfo(message) {
      this.$store.commit('messages/sendInfo', message);
    },
    sendSuccess(message) {
      this.$store.commit('messages/sendSuccess', message);
    },
    getMessages() {
      return this.$store.getters[`messages/getMessages`];
    },
    getNotifHistory() {
      return this.$store.getters[`messages/getHistory`];
    },
    deleteMessage(message) {
      this.$store.commit('messages/deleteMessage', message);
    }
  }
}