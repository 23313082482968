<template>
  <div class="message-overlay ma-5">
    <message-toast
      v-for="(message, i) in getMessages()"
      :key="Date.now() + i + message.type"
      :message="message"
    />

    <v-btn
      @click="showMessageHistory = true, sendInfo({ text: 'Test.' })"
      fab
      style="border: 1px solid white !important"
      small
      color="black"
    >
      <v-icon color="white" small>mdi-message-bulleted</v-icon>
    </v-btn>

    <v-dialog width="max-content" v-model="showMessageHistory"> 
      <v-card>
        <v-card-title class="open-sans" style="text-transform: uppercase; font-weight: 900">
          <v-icon class="mr-3" color="white">mdi-message-bulleted</v-icon>
          Benachrichtigungsverlauf
          <v-spacer></v-spacer>
          <v-btn class="ml-2" icon x-small fab @click="showMessageHistory = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2 d-flex flex-row justify-end">
          <v-btn @click="exportMessages()" class="rounded-pill mr-2" x-small outlined>
            Exportieren
          </v-btn>
          <v-btn @click="clearMessages()" class="rounded-pill" x-small outlined>
            Verlauf löschen
          </v-btn>
        </v-card-text>
        <v-card-text style="height: 380px; max-height: 380px; overflow-y: scroll">
          <div v-for="(notif, i) of getNotifHistory()" :key="'msg-hst-' + Date.now() + `${i}`">
            <v-sheet class="mb-2 rounded-lg pa-2 d-flex flex-row justify-center" :color="notif.type">
              <v-icon class="mr-2">{{ notif.icon }}</v-icon>
              <span>{{ notif.text }}</span>
              <v-spacer></v-spacer>
              <span class="mr-2">{{ new Date(notif.ts).toLocaleTimeString() }}</span>
            </v-sheet>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SystemMessageMixin from "../../../plugins/mixins/SystemMessageMixin";
import MessageToast from "./MessageToast.vue";

export default {
  components: { MessageToast },
  mixins: [SystemMessageMixin],
  data: () => ({
    showMessageHistory: false,
  }),
};
</script>

<style scoped>
.message-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
