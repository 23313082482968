<template>
  <v-app-bar
    app
    color="navcolor"
    id="micharge-app-bar"
    style="user-select: none"
    height="64"
    dark
    elevate-on-scroll
  >
    <div class="d-flex flex-row align-center" style="color: white">
      <v-expand-x-transition>
        <BurgerMenuButton
          ref="mobileNavigation"
          @click="openMobileMenu = !openMobileMenu"
          v-if="isMobile"
        />
      </v-expand-x-transition>

      <transition name="slide">
        <div v-if="openMobileMenu && isMobile" class="fullscreen-menu">
          <ul class="menu-list">
            <li
              v-for="menuItem in menuItems"
              class="menu-item"
              :key="menuItem.name"
            >
              <span @click="navigatePage(menuItem.link)">
                <v-icon large class="menu-item-icon">
                  {{ menuItem.icon }}
                </v-icon>
                {{ menuItem.name }}
              </span>
            </li>
          </ul>
        </div>
      </transition>
      <div
        @click="navigatePage('/')"
        :class="isMobile ? 'logo-container-mobile' : 'logo-container-desktop'"
      >
        <v-img
          :class="isMobile ? 'ml-3' : 'mr-3'"
          :src="miChargeLogo"
          width="56"
        />
      </div>

      <v-expand-x-transition>
        <v-app-bar-title class="open-sans" v-if="!isMobile">
          <a
            style="color: white; text-decoration: none"
            @click="navigatePage('/')"
            >MiCharge</a
          >
        </v-app-bar-title>
      </v-expand-x-transition>
    </div>

    <v-spacer />

    <app-search-bar />

    <v-spacer />

    <device-history-overlay />

    <v-btn
      v-if="isAdmin"
      icon
      class="mr-4"
      @click="$store.state.showErrorDialog = true"
      title="Fehlermeldungen"
    >
      <v-badge v-if="hasErrors" color="#AA1D22" :content="errorCount">
        <v-icon>mdi-bell</v-icon>
      </v-badge>
      <v-icon v-else>mdi-bell</v-icon>
    </v-btn>

    <UserMenu />
  </v-app-bar>
</template>

<script>
import miChargeLogo from "../../../../../_common/assets/svg/EHGBatteryLogo.svg";
// import loginHandler from "../../../plugins/loginHandler";
import DeviceHistoryOverlay from "./DeviceHistoryOverlay.vue";
import BurgerMenuButton from "./barComponent/BurgerMenuButton.vue";
import AppSearchBar from "./barComponent/AppSearchBar.vue";
import dbHelper from "../../../db/DBHelper";
import UserMenu from "../navs/UserMenu.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    DeviceHistoryOverlay,
    BurgerMenuButton,
    AppSearchBar,
    UserMenu,
  },

  async mounted() {
    const logs = await dbHelper.Charger.getDeviceErrors();
    this.$store.state.errors = logs;
    // get logs => listen to websocket for "Type: error" or similar?
  },

  data() {
    return {
      miChargeLogo: miChargeLogo,
      openMobileMenu: false,

      menuItems: [
        { name: "Startseite", link: "/", icon: "mdi-home" },
        { name: "Admin Übersicht", link: "/admin", icon: "mdi-account" },
        { name: "Einstellungen", link: "/settings", icon: "mdi-cogs" },
      ],
    };
  },

  computed: {
    ...mapGetters(["isMobile"]),
    ...mapGetters("user", ["isAdmin", "currentUser"]),

    hasErrors() {
      const errors = this.$store.state.errors;
      return errors && errors.length > 0;
    },

    errorCount() {
      const errors = this.$store.state.errors;
      if (!errors || errors.length == 0) return 0;
      return errors.length;
    },
  },

  methods: {
    navigatePage(link) {
      if (this.$route.path == link) return;
      if (this.isMobile) {
        this.$refs.mobileNavigation.close();
        this.openMobileMenu = false;
      }
      this.$router.push(link);
    },
  },

  watch: {},
};
</script>

<style lang="scss">
// Fix Text Clipping
.v-app-bar-title__content {
  width: auto !important;
  font-weight: 600;
}

.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--v-navcolor-base);
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-enter-active {
  transition: transform 0.3s;
}

.slide-leave-active {
  transition: transform 0.3s;
}

.slide-enter {
  transform: translateX(-100%);
}

.slide-leave-to {
  transform: translateX(-100%);
}

.menu-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.menu-item {
  font-size: 36px;
  margin-bottom: 24px;
  text-decoration: none;
}

.logo-container-desktop:hover {
  cursor: pointer;
}
</style>
