import dbHelper from "../../../db/DBHelper";
import Rack from "../../../db/classes/rack";
import { Logging } from "../../../miConfig";

const logIt = Logging.Rack.STORE

export default {
  namespaced: true,
  name: "rack",
  state: {
    racks: [],
    currentRack: null,
  },

  mutations: {
    SET_RACKS(state, racks) {
      state.racks = racks.map(rack =>
        rack instanceof Rack ? rack : new Rack(rack)
      );
      if (logIt) console.log("SET_RACKS", state.racks);
    },

    SET_RACK(state, rack) {
      const index = state.racks.findIndex(r => r.id === rack.id);
      if (index !== -1) {
        state.racks[ index ] = new Rack(rack);
      } else {
        state.racks.push(new Rack(rack));
      }
      if (logIt) console.log("SET_RACK", rack);
    },

    UPDATE_RACK(state, rack) {
      const existingRack = state.racks.find(r => r.id === rack.id);
      const updatedRack = new Rack({
        ...existingRack,
        ...rack
      });
      const index = state.racks.findIndex(r => r.id === rack.id);
      state.racks[ index ] = updatedRack;
      if (logIt) console.log("UPDATE_RACK", rack);
    },

    REMOVE_RACK(state, rackId) {
      const index = state.racks.findIndex(r => r.id === rackId);
      if (index !== -1) {
        state.racks.splice(index, 1);
      }
      if (logIt) console.log("REMOVE_RACK", rackId);
    },

    SET_CURRENT_RACK(state, rack) {
      state.currentRack = new Rack(rack);
      if (logIt) console.log("SET_CURRENT_RACK", rack);
    },
  },

  actions: {
    async fetchRacks({ commit }) {
      try {
        const racks = await dbHelper.Rack.getRacks();
        const local = JSON.parse(localStorage.getItem("racks"))
        commit("SET_RACKS", [ ...(racks || []), ...(local || []) ]);
      } catch (error) {
        console.error(`Failed to fetch racks: ${error}`);
      }
    },

    setCurrentRack({ commit }, rack) {
      commit("SET_CURRENT_RACK", rack);
    },

    async addRack({ state, commit }, rack) {
      if (!rack) return console.warn("Cannot add Rack:", rack);
      try {
        console.log("Adding rack", rack);
        rack = await dbHelper.Rack.addRack(rack)
        commit("SET_RACK", rack);
        localStorage.setItem("racks", JSON.stringify(state.racks))
      } catch (error) {
        console.error(error);
        // Dev
        rack.id = Date.now()
        commit("SET_RACK", rack);
      }
    },

    async saveRack({ commit }, { id, rack }) {
      if (!rack) return console.warn("Cannot save Rack:", rack);
      await dbHelper.Rack.updateRack(id ? id : rack.id, rack)
      commit("UPDATE_RACK", rack);
    },

    async removeRack({ commit }, { id }) {
      if (!id) return console.warn("Cannot remove RackId", id);
      let success = await dbHelper.Rack.removeRack(id)
      console.log(success)
      commit("REMOVE_RACK");
    },

    async updateRack({ commit }, rack) {
      let success = await dbHelper.Rack.updateRack(rack.id, rack)
      commit("UPDATE_RACK", rack)
      console.log("updateRack", success);
    },

    async updateRackSlot({ commit }, { rack, index, update }) {
      rack.slots[ index ] = update
      commit("UPDATE_RACK", rack)
    }
  },

  getters: {
    getRacks: (state) => state.racks,
    getRackIDs: (state) => state.racks.map(rack => rack.id),
    getRackByID: (state) => (id) =>
      state.racks.find(rack => rack.id === id),
    getRackCount: (state) => state.racks.length,

    getCurrentRack: (state) => state.currentRack,

    getRacksForBuildingID: (state) => (buildingId) =>
      state.racks.filter(rack => rack.relation.bldgId == buildingId),
  },
};
