<template>
  <v-menu
    nudge-width="250"
    content-class="rounded-xl"
    v-model="open"
    :close-on-content-click="false"
    :nudge-bottom="60"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        v-ripple
        class="mr-2"
        color="accent"
        title="Benutzer wechseln"
        v-bind="attrs"
        v-on="on"
        size="40"
      >
        {{ userInitials }}
      </v-avatar>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar v-ripple color="accent" size="40">
              {{ userInitials }}
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ userName }}</v-list-item-title>
            <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item @click="navigate('/settings')">
          <v-list-item-icon>
            <v-icon> mdi-cog </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Einstellungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon> mdi-theme-light-dark </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Dark Mode</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-switch inset color="green" v-model="$vuetify.theme.dark" />
          </v-list-item-action>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon> mdi-human </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Barrierefreiheit</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-switch
              inset
              color="green"
              v-model="$store.state.enableAccessibility"
              title="Barrierefreiheit aktivieren"
            />
          </v-list-item-action>
        </v-list-item>

        <v-divider />

        <v-list-item @click="loggedIn ? logOut() : logIn()">
          <v-list-item-icon>
            <v-icon> mdi-power </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ loggedIn ? "Abmelden" : "Anmelden" }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>

    <v-dialog
      width="max-content"
      v-model="confirmLogout"
      content-class="rounded-xl"
    >
      <v-card>
        <v-card-text class="pa-4">
          <span>
            Möchtest du den Benutzer <strong>{{ userName }}</strong> wirklich
            abmelden?
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="red" @click="confirmLogout = false">
            Abbrechen
          </v-btn>
          <v-btn text color="blue" @click="logOutConfirm()">Bestätigen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-menu>
</template>

<script>
import loginHandler from "../../../plugins/loginHandler";

export default {
  data: () => ({
    open: false,
    confirmLogout: false,
  }),
  computed: {
    userInitials() {
      let user = this.$store.state.userObject;
      if (user == null) user = this.$store.state.defaultUser;
      return user.initials;
    },
    currentUser() {
      let user = this.$store.state.userObject;
      if (user == null) user = this.$store.state.defaultUser;
      return user;
    },
    loggedIn() {
      let user = this.$store.state.userObject;
      if (user == null) user = this.$store.state.defaultUser;
      return user._id != "-1";
    },
    userName() {
      let user = this.$store.state.userObject;
      if (user == null) return "Gast";

      const { firstname, surname } = this.$store.state.userObject.profile;
      return `${firstname} ${surname}`;
    },
  },
  watch: {
    "$vuetify.theme.dark"(darkMode) {
      if (darkMode) localStorage.setItem("color-scheme", "dark");
      else localStorage.setItem("color-scheme", "light");
    },
    "$store.state.enableAccessibility"(accessibility) {
      if (accessibility) localStorage.setItem("accessibility-mode", true);
      else localStorage.setItem("accessibility-mode", false);
    },
  },
  methods: {
    navigate(path) {
      const currentPath = this.$route.path;
      if (path != currentPath) this.$router.push(path);
      this.open = false;
    },

    logIn() {
      this.$store.state.showDialogUser = true;
    },

    logOut() {
      this.confirmLogout = true;
    },

    logOutConfirm() {
      if (this.$route.path != "/") this.$router.push("/");
      loginHandler.logout();

      this.confirmLogout = false;
      this.open = false;
    },
  },
};
</script>
