<template>
  <div class="burger-menu-button" @click="toggleMenu">
    <div class="burger-menu-bar" :class="{ active: menuOpen }"></div>
    <div class="burger-menu-bar" :class="{ active: menuOpen }"></div>
    <div class="burger-menu-bar" :class="{ active: menuOpen }"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
      this.$emit('click', this.menuOpen);
    },
    close() {
      this.menuOpen = false;
    },
    open() {
      this.menuOpen = true;
    }
  }
};
</script>

<style scoped>
.burger-menu-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-top: 6px;
  z-index: 1000;
}

.burger-menu-bar {
  width: 100%;
  height: 4px;
  background-color: white;
  margin-bottom: 4px; /* Add spacing between bars */
  transition: transform 0.3s, opacity 0.3s;
  border-radius: 10px;
}

.burger-menu-bar.active:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.burger-menu-bar.active:nth-child(2) {
  opacity: 0;
}

.burger-menu-bar.active:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}
</style>