// import dbHelper from "../../db/DBHelper";

import Battery from "../../db/classes/battery";
import Charger from "../../db/classes/charger";
import Rack from "../../db/classes/rack";
import { Logging } from "../../miConfig";

// Debug
const logIt = Logging.User.STORE;

// export const PINNED_LOCATIONS = "locs";
// export const PINNED_BUILDINGS = "bldgs";
export const PINNED_RACKS = "racks";
export const PINNED_CHARGERS = "chrgs";
export const PINNED_BATTERIES = "bttrs";

export default {
  namespaced: true,
  name: "app",
  state: {
    isOffline: false,
    deviceHistory: [],
    enableDrag: true,

    preffered: {
      locationID: null,
      buildingID: null,
      // rackID : null
    },

    pinned: {
      [ PINNED_RACKS ]: [],
      [ PINNED_CHARGERS ]: [],
      [ PINNED_BATTERIES ]: [],
    },
  },

  mutations: {
    SET_OFFLINE(state, value) {
      state.isOffline = value;
      if (logIt) console.log("SET_OFFLINE", state.isOffline);
    },

    SET_ENABLE_DRAG(state, enable) {
      state.enableDrag = enable
    },

    SET_DEVICE_HISTORY(state, history) {
      state.deviceHistory = history;
      if (logIt) console.log("SET_DEVICE_HISTORY", state.deviceHistory);
    },

    // Preferred
    SET_PREFERRED_LOCATION_ID(state, locationID) {
      state.preferred.locationID = locationID;
      if (logIt) console.log("SET_PREFERRED_LOCATION_ID", state.preferred.locationID);
    },

    SET_PREFERRED_BUILDING_ID(state, buildingID) {
      state.preferred.buildingID = buildingID;
      if (logIt) console.log("SET_PREFERRED_BUILDING_ID", state.preferred.buildingID);
    },

    // Pinned
    SET_PINNED_ITEMS(state, { key, values }) {
      let itemsArray = values;
      if (!Array.isArray(values))
        itemsArray = Object.values(values);
      state.pinned[ key ] = itemsArray
      if (logIt) console.log("SET_PINNED_ITEMS", key, state.pinned[ key ]);
    },

    ADD_PINNED_ITEM(state, { key, value }) {
      state.pinned[ key ].push(value);
      if (logIt) console.log("ADD_PINNED_ITEM", key, state.pinned[ key ]);
    },

    REMOVE_PINNED_ITEM(state, { key, value }) {
      const index = state.pinned[ key ].indexOf(value);
      if (index > -1) {
        state.pinned[ key ].splice(index, 1);
      }
      if (logIt) console.log("REMOVE_PINNED_ITEM", state.pinned[ key ]);
    },

  },

  actions: {
    // Fetch
    async fetchAppData({ commit, dispatch }) {
      try {
        await Promise.all([
          dispatch("fetchPinned"),
          dispatch("fetchPreffered"),
          dispatch("fetchDeviceHistory"),
        ])
        commit("SET_OFFLINE", false);
      } catch (err) {
        console.error("Error in fetchAppData", err);
        dispatch("error/addError", err, { root: true });
        commit("SET_OFFLINE", true);
      }
    },

    // Flags
    setEnableDrag({ commit }, enable) {
      commit("SET_ENABLE_DRAG", enable)
      if (logIt) console.log("Enable drag");
    },

    // Preffered
    async fetchPreffered({ commit }) {
      // TODO: DB call (user? / workspace?)
      if (localStorage.getItem("currentLocationID"))
        commit("SET_PREFERRED_LOCATION_ID", localStorage.getItem("currentLocationID"));

      if (localStorage.getItem("currentBuildingID"))
        commit("SET_PREFERRED_BUILDING_ID", localStorage.getItem("currentBuildingID"));
    },

    async setPreferredLocation({ commit }, locID) {
      // TODO: Preffered DB-Save Logics
      commit("SET_PREFERRED_LOCATION_ID", locID);
      // dbHelper
    },

    async setPreferredBuilding({ commit }, locID) {
      // TODO: Preffered DB Logic
      commit("SET_PREFERRED_BUILDING_ID", locID);
      // dbHelper
    },

    // Pinned
    async fetchPinned({ commit }) {
      // TODO: DB fetch
      const pinnedChargers = JSON.parse(localStorage.getItem("pinnedChargers"))
      const pinnedBatteries = JSON.parse(localStorage.getItem("pinnedBatteries"))

      if (pinnedChargers)
        commit("SET_PINNED_ITEMS", {
          key: PINNED_CHARGERS,
          values: pinnedChargers || []
        })

      if (pinnedBatteries)
        commit("SET_PINNED_ITEMS", {
          key: PINNED_BATTERIES,
          values: pinnedBatteries || []
        })
    },

    async togglePinnedCharger({ state, commit }, chargerID) {
      const pinnedItems = state.pinned[ PINNED_CHARGERS ]
      const isPinned = pinnedItems.includes(chargerID)
      const mutationName = isPinned ? "REMOVE_PINNED_ITEM" : "ADD_PINNED_ITEM"
      commit(mutationName, {
        key: PINNED_CHARGERS,
        value: chargerID
      });
      // TODO: DB
      localStorage.setItem("pinnedChargers", JSON.stringify(pinnedItems))
    },

    async togglePinnedBattery({ state, commit }, batteryID) {
      const pinnedItems = state.pinned[ PINNED_BATTERIES ]
      const isPinned = pinnedItems.includes(batteryID)
      const mutationName = isPinned ? "REMOVE_PINNED_ITEM" : "ADD_PINNED_ITEM"
      commit(mutationName, {
        key: PINNED_BATTERIES,
        value: batteryID
      });

      // TODO: DB
      localStorage.setItem("pinnedBatteries", JSON.stringify(pinnedItems))
    },

    // ?
    async fetchDeviceHistory({ commit }) {
      const deviceHistory = JSON.parse(localStorage.getItem("deviceHistory"));
      if (!deviceHistory) {
        localStorage.setItem("deviceHistory", "[]");
        return;
      }

      commit("SET_DEVICE_HISTORY", deviceHistory);
    },
  },

  getters: {
    isOffline: (state) => state.isOffline,

    // Locations
    getPrefferedLocation: (state, _, __, rootGetters) =>
      rootGetters[ "location/getLocationByID" ](state.preffered.locationID),
    getPrefferedBuilding: (state, _, __, rootGetters) =>
      rootGetters[ "building/getBuildingByID" ](state.preffered.buildingID),
    // getPrefferedRack: (state, _, __, rootGetters) =>
    // rootGetters[ "rack/getRackByID" ](state.preffered.rackID),

    getPinnedRackIDs: (state) => state.pinned[ PINNED_RACKS ] || [],
    getPinnedChargerIDs: (state) => state.pinned[ PINNED_CHARGERS ] || [],
    getPinnedBatteryIDs: (state) => state.pinned[ PINNED_BATTERIES ] || [],

    getPinnedRacks: (_, getters, __, rootGetters) =>
      getters.getPinnedRackIDs.map(id => rootGetters[ "rack/getRackByID" ](id)),
    getPinnedChargers: (_, getters, __, rootGetters) => {
      let chargers = getters.getPinnedChargerIDs.map(id => rootGetters[ "charger/getChargerByID" ](id))
      return chargers.filter(it => !!it)
    },
    getPinnedBatteries: (_, getters, __, rootGetters) => {
      const batteries = getters.getPinnedBatteryIDs.map(id => rootGetters[ "battery/getBatteryByID" ](id))
      return batteries.filter(it => !!it)
    },

    // has
    hasPinned: (_, getters) => getters.hasPinnedChargers && getters.hasPinnedBatteries,
    // hasPinnedRacks: (_, getters) => getters.getPinnedRackIDs.length > 0,
    hasPinnedChargers: (_, getters) => getters.getPinnedChargerIDs.length > 0,
    hasPinnedBatteries: (_, getters) => getters.getPinnedBatteryIDs.length > 0,

    // is(id)
    isPinnedRack: (_, getters) => id => getters.getPinnedRackIDs.includes(id),
    isPinnedCharger: (_, getters) => id => getters.getPinnedChargerIDs.includes(id),
    isPinnedBattery: (_, getters) => id => getters.getPinnedBatteryIDs.includes(id),

    // icon
    getPinnedIconFor: (_, getters) => item => {
      if (!item) return console.warn("No PinnedIcon for undefined", item);
      let icon = "mdi-pin";

      if (item instanceof Charger && getters.isPinnedCharger(item.id)) {
        return icon;
      } else if (item instanceof Rack && getters.isPinnedRack(item.id)) {
        return icon;
      } else if (item instanceof Battery && getters.isPinnedBattery(item.id)) {
        return icon;
      }

      icon += "-outline"
      if (logIt) console.log("Got icon for", item, icon);
      return icon;
    }
  }
}
