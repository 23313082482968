import { Commands, Logging, PostPaths } from "../../miConfig";
import * as Base from './BaseModule';

const logCom = Logging.charger.COMMANDS

// * * * * * * * * * * * * * * * * * * * *
// *             CHARGER COMMANDS        *
// * * * * * * * * * * * * * * * * * * * *

export async function setSlotCharge(chargerId, slotIndex, mode) {
    const command = {
        cmd: Commands.SET_CURR_SLOT,
        mcg: chargerId,
        target: slotIndex,
        value: mode.current,
    };
    const res = await sendCommand(command);
    if (logCom) console.log("SlotChargeCommand result", res);
    return res
}

export class ChargerCommands {
    constructor(charger) {
        if (charger) {
            this.id = charger.id
        }
    }

    // Mode
    async setChargeMode(modeID) {
        const command = this.#createCommand(Commands.SET_CURR_GLOBAL, modeID);
        const res = await sendCommand(command);
        if (logCom) console.log("ChargeMode result", modeID, res)
        return res
    }

    // Profile
    async setChargeProfile(profileID) {
        const command = this.#createCommand(Commands.SET_PROFILE, profileID);
        const res = await sendCommand(command);
        if (logCom) console.log("ChargeProfile result", res);
        return res
    }

    // Custom
    async setSlotCharge(slotIndex, mode) {
        const command = this.#createCommand(Commands.SET_CURR_SLOT, mode, `${slotIndex}`);
        const res = await sendCommand(command);
        if (logCom) console.log("SlotChargeCommand result", res);
        return res
    }

    // Else
    async playSound() {
        const command = this.#createCommand(Commands.ALERT);
        if (logCom) console.log("Sending Charger playSound");
        const res = await sendCommand(command);
        if (logCom) console.log("PlaySound result", res);
        return res
    }

    async resetCharger() {
        if (logCom) console.log("Sending Charger resetCharger");
        const command = this.#createCommand(Commands.RESET);
        return await sendCommand(command);
    }

    async shutOff() {
        if (logCom) console.log("Sending Charger shutOff");
        const command = this.#createCommand(Commands.SHUT_OFF);
        return await sendCommand(command);
    }


    // End commands

    async updateFirmware() {
        await Base.post(PostPaths.REQUEST_FIRMWARE, this.id);
    }

    /**
       * Commands 
       * @param target (Optional) e.g. SlotId?
       * */
    #createCommand(cmd, value = "", target = "") {
        return {
            cmd,
            target,
            value,
            mcg: this.id
        }
    }
}

async function sendCommand({ mcg, cmd, target, value }) {
    if (!mcg || !cmd) {
        return;
    }
    if (logCom) console.log(`Sending Command '${cmd}' to Charger '${mcg}' with value ${value} and target ${target}.`);
    return await Base.post(PostPaths.SEND_MCG_COMMAND, { mcg, cmd, target, value });
}
