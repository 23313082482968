import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

import config from "../../../../_res/config.json";
import icons from "./icons";

const opt = {
  theme: {
    dark: true,
    options: {
      customProperties: true,
      // themeCache: {
      //     get: key => localStorage.getItem(key),
      //     set: (key, value) => localStorage.setItem(key, value),
      // },
    },
    ...config.theme,
  },

  icons: {
    iconfont: "mdi", // default
    values: {
      ...icons,
    },
  },
};

export default new Vuetify(opt);
