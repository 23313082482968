// TODO: Struct überarbeiten
import { cloneDeep } from "lodash";
import Struct from "./data/battery.json";
import Vue from "vue";

const healthIndexTexts = {
  5: "Die Batterie befindet sich<br>in einem sehr guten Zustand.",
  4: "Die Batterie befindet sich<br>in einem guten Zustand.",
  3: "Die Batterie zeigt erste Anzeichen von Alterung.<br>Beobachten Sie ihren Zustand.",
  2: "Die Batterie ist deutlich gealtert.<br>Ein Ersatz in naher Zukunft wird empfohlen.",
  1: "Die Batterie ist stark gealtert und könnte bald versagen.<br>Ein sofortiger Ersatz wird dringend empfohlen.",
};

const healthIndexColors = {
  "?": "gray",
  5: "#109509",
  4: "#889818",
  3: "#FF9A26",
  2: "#E36021",
  1: "#C7251C",
};

export default class Battery {
  constructor(battery) {
    Object.assign(this, Struct);
    if (battery) {
      Object.assign(this, battery);
      this.id = battery.meta.id;
    }
    this.data.lastSeen = new Date().getTime();
  }

  // TODO: Set to new Sessions
  setCycles(cycles) {
    const modeCounts = {};
    let totalDurationMs = 0;

    cycles.forEach(cycle => {
      if (cycle.initialMode || cycle.initialMode === 0) {
        const currentCount = modeCounts[ cycle.initialMode ] || 0;
        modeCounts[ cycle.initialMode ] = currentCount + 1;
      }
      totalDurationMs += cycle.endTime - cycle.startTime;
    });

    Vue.set(this, "cycles", cycles)
    Vue.set(this, "modeCounts", modeCounts)
    Vue.set(this, "totalDurationMs", totalDurationMs)
  }


  get percentCharged() {
    if (!this.data?.socr) return 0;
    return this.data.socr;
  }

  get timeToFull() {
    let ttfavg = this.data.ttfavg;
    let hours = Math.floor(ttfavg / 60);
    let min = Math.floor(ttfavg % 60);
    if (!hours && !min) return "~"
    return hours > 0 ? `~ ${hours}h ${min}min` : `${min}min`;
  }

  // Outsource
  get totalTime() {
    if (!this.totalDurationMs) {
      return console.warn("Cant compute total time w/o duration");
    }

    const totalMinutes = Math.floor(this.totalDurationMs / (1000 * 60));
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    const hoursString = hours > 0 ? `${hours} Stunden` : "";
    const minutesString = minutes > 0 ? `${minutes} Minuten` : "";

    return [ hoursString, minutesString ].filter(Boolean).join(" und ") || "0 Minuten";
  }

  // Health Index
  get healthIndex() {
    let manuCap = this.meta.ccapdes;
    let curCap = this.data?.fccap ?? 0;
    if (!curCap) this.healthIndex = "?";

    let charge = Math.floor((curCap / manuCap) * 100);
    if (charge > 85) return 5;
    if (charge > 70) return 4;
    if (charge > 50) return 3;
    if (charge > 25) return 2;
    return 1;
  }

  get healthIndexText() {
    return healthIndexTexts[ this.healthIndex ];
  }

  get healthIndexColor() {
    return healthIndexColors[ this.healthIndex ];
  }

  // DB
  toSaveable() {
    const saveable = cloneDeep(this)
    delete saveable.cycles
    delete saveable.modeCounts
    delete saveable.totalDurationMs
    return saveable
  }
}
