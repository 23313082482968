import Struct from "./data/chargeStep.json";

export default class ChargeStep {
  constructor(step) {
    Object.assign(this, Struct);
    if (step) {
      try {
        this.ts = step.data.ts;
        delete step.data.ts;
        Object.assign(this, step);
        this.data.temp = this.data.temp / 100;
        this.data.volt = parseFloat((this.data.volt / 1000).toFixed(2));
      } catch (error) {
        //
      }
    }
  }

  // TODO: Set Anständig!
  setSlot(slot) {
    this.ts = slot.lastData.ts;
    if (slot.chargemode)
      this.chargeMode = slot.chargemode
    this.data = {
      ...this.data,
      curr: slot.lastData.curr,
      curravg: slot.lastData.curravg,
      cyccnt: slot.lastData.cyccnt,
      fccap: slot.lastData.fccap,
      soca: slot.lastData.soca,
      socr: slot.lastData.socr,
      temp: slot.lastData.temp / 100,
      ttfavg: slot.lastData.ttfavg,
      volt: parseFloat((slot.lastData.volt / 1000).toFixed(2)),
    };
  }

  // TODO: Set Anständig!
  setBattery(battery) {
    this.ts = battery.data.ts;
    if (battery.meta?.chargeMode)
      this.chargeMode = battery.meta?.chargemode
    this.data = {
      ...this.data,
      chargeMode: battery.meta.chargeMode,
      curr: battery.data.curr,
      curravg: battery.data.curravg,
      cyccnt: battery.data.cyccnt,
      fccap: battery.data.fccap,
      soca: battery.data.soca,
      socr: battery.data.socr,
      temp: battery.data.temp / 100,
      ttfavg: battery.data.ttfavg,
      volt: parseFloat((battery.data.volt / 1000).toFixed(2)),
    };
  }
}
