// locationModule.js
import dbHelper from "../../../db/DBHelper";
import MiLocation from "../../../db/classes/location";
import { Logging } from "../../../miConfig";

const logIt = Logging.Location.STORE;

export default {
  namespaced: true,
  name: "location",

  state: {
    locations: [],
    currentLocation: null,
  },

  mutations: {
    SET_LOCATIONS(state, locations) {
      if (logIt) console.log("Locations", locations);

      if (Array.isArray(locations)) {
        state.locations = locations.map(location =>
          location instanceof MiLocation ? location : new MiLocation(location)
        );
      } else {
        state.locations = Object.values(locations).map(location =>
          location instanceof MiLocation ? location : new MiLocation(location)
        );
      }

      if (logIt) {
        console.log("SET_LOCATIONS", state.locations);
        console.table(state.locations);
      }
    },

    SET_LOCATION(state, location) {
      if (!(location instanceof MiLocation)) {
        location = new MiLocation(location);
      }

      const index = state.locations.findIndex(loc => loc.id === location.id);
      if (index !== -1) {
        state.locations[ index ] = location;
      } else {
        state.locations.push(location);
      }

      if (logIt) {
        console.log("SET_LOCATION", state.locations);
      }
    },

    UPDATE_LOCATION(state, location) {
      const existingLocation = state.locations.find(loc => loc.id === location.id);
      if (existingLocation) {
        location = new MiLocation({
          ...existingLocation,
          ...location
        });
        const index = state.locations.findIndex(loc => loc.id === location.id);
        state.locations[ index ] = location;
      }

      if (logIt) {
        console.log("UPDATE_LOCATION", state.locations);
      }
    },

    REMOVE_LOCATION(state, locID) {
      const index = state.locations.findIndex(loc => loc.id === locID);
      if (index !== -1) {
        state.locations.splice(index, 1);
      }

      if (logIt) {
        console.log("REMOVE_LOCATION", state.locations);
      }
    },

    SET_CURRENT_LOCATION(state, location) {
      state.currentLocation = location;
      if (logIt) {
        console.log("SET_CURRENT_LOCATION", state.currentLocation);
      }
    },
  },

  actions: {
    async fetchLocations({ commit }) {
      const locations = await dbHelper.Location.getLocations();
      commit("SET_LOCATIONS", locations.locations || []);
    },

    async fetchLocationById({ commit }, locationId) {
      let location = await dbHelper.Location.getLocationByID(locationId);
      commit("SET_LOCATION", location);
    },

    // CurrentLocation
    async setCurrentLocationID({ getters, dispatch }, id) {
      dispatch("setCurrentLocation", getters.getLocationByID(id));
      dbHelper.Location.listenTo(
        id,
        found => dispatch("setCurrentLocation", found),
        err => console.error(err)
      )
    },

    setCurrentLocation({ commit }, location) {
      commit("SET_CURRENT_LOCATION", location);
    },

    async addLocation({ commit, dispatch }, location) {
      try {
        location = await dbHelper.Location.addLocation(location)
        commit("SET_LOCATION", location)
      } catch (error) {
        console.error(error);
        dispatch("error/addError", error, { root: true })
      }
    },

    async updateLocation({ commit, dispatch }, { id, location }) {
      try {
        await dbHelper.Location.updateLocation(id ? id : location.id, location)
        commit("UPDATE_LOCATION", location);
      } catch (error) {
        dispatch("error/addError", error, { root: true })
        console.error(error);
      }
    },

    async removeLocation({ commit }, { id }) {
      if (!id) return console.warn("Cannot remove LocationId", id);
      try {
        const res = await dbHelper.Location.removeLocation(id)
        console.log("RES", res);
        if (res)
          commit("REMOVE_LOCATION", id)
        return res
      } catch (error) {
        console.error(error);
      }
      commit("REMOVE_LOCATION", id)
    }
  },

  getters: {
    getLocations: (state) => state.locations,
    getLocationIDs: (state) => state.locations.map(location => location.id),
    getLocationByID: (state) => (id) =>
      state.locations.find(location => location.id === id),

    getCurrentLocation: (state) => state.currentLocation,

    getPrefferedLocation: (_, __, ___, rootGetters) =>
      rootGetters[ "app/getPrefferedLocation" ],
    // Bools
    hasLocations: (_, getters) => getters.getLocations.length > 0
  },
};
