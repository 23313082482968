import ChargeStep from "./chargeStep";
import Struct from "./data/chargeSession.json";

export default class ChargeSession {
  constructor(steps) {
    Object.assign(this, Struct);
    if (steps?.length) {
      // Sort the steps by timestamp
      steps.sort((a, b) => a.data.ts - b.data.ts);

      let first = steps.at(0);
      let last = steps.at(-1);
      this.id = first._id || null;
      this.slotIndex = first.parent?.slot || null;
      this.batteryID = first.deviceId;
      this.chargerID = first.parent?.charger || null;

      this.location = {
        rackID: null, // This data is not provided in the provided structure
        locID: null, // This data is not provided in the provided structure
        bldgID: null, // This data is not provided in the provided structure
      }

      this.start = {
        charge: first.data?.soca || 0,
        ts: first.data?.ts || 0,
        mode: first.data?.chargeMode || -1,
      };

      this.end = {
        charge: last.data?.soca || 0,
        ts: last.data?.ts || 0,
        mode: last.data?.chargeMode || -1,
      };
      const modes = {}
      this.steps = steps ? steps.map((it) => {
        const step = new ChargeStep(it)
        modes[ it.chargeMode ]++
        return step
      }) : [];
      this.modes = modes
    }
  }
}
