import webSocketHelper, { SUBSCRIBE_CHARGER } from '../WebSocketHelper';
import * as Base from './BaseModule';
import {
  Logging,
  GetPaths,
  PostPaths
} from '../../miConfig';
import Charger from '../classes/charger';

const logIt = Logging.db || Logging.charger.db;


export class ChargerModule {

  // * * * * * * * * * * * * * * * * * * * *
  // *               GETTERS               *
  // * * * * * * * * * * * * * * * * * * * *

  async getChargerById(id) {
    const path = GetPaths.CHARGER + id;
    const item = await Base.get(path, { params: { batteries: true } });
    return item ? new Charger(item) : undefined
  }

  async getChargers(locId = -1, bldgId = -1, rackId = -1) {
    const queryString = `?location=${locId}&building=${bldgId}&rack=${rackId}`;
    const chargers = await Base.get(GetPaths.ALL_CHARGERS + queryString) ?? [];
    return chargers.map(charger => new Charger(charger));
  }

  async getChargersByLocation(locId = -1) {
    const queryString = `?location=${locId}`;
    const chargers = await Base.get(GetPaths.ALL_CHARGERS + queryString) ?? [];
    return chargers.map(charger => new Charger(charger));
  }

  async getChargersByBuilding(locId = -1, bldgId = -1) {
    const queryString = `?location=${locId}&building=${bldgId}`;
    const chargers = await Base.get(GetPaths.ALL_CHARGERS + queryString) ?? [];
    return chargers.map(charger => new Charger(charger));
  }

  async getChargersByRack(locId = -1, bldgId = -1, rackId = -1) {
    const queryString = `?location=${locId}&building=${bldgId}&rack=${rackId}`;
    const chargers = await Base.get(GetPaths.ALL_CHARGERS + queryString) ?? [];
    return chargers.map(charger => new Charger(charger));
  }

  getChargerSync(id, exec) {
    let path = `/charger/${id}`;
    Base.getSync(path, {}, exec);
  }

  async getDeviceErrors() {
    return (await Base.get(GetPaths.ALL_LOGS));
  }

  listenTo(chargerId, onFound, onError) {
    try {
      if (logIt) console.log("Listen to", chargerId);
      const unsubFun = webSocketHelper
        .addListener(
          SUBSCRIBE_CHARGER,
          chargerId,
          (item) => onFound(new Charger(item))
        );
      return unsubFun;
    } catch (error) {
      onError(error);
    }
  }

  // * * * * * * * * * * * * * * * * * * * *
  // *              MODIFIERS              *
  // * * * * * * * * * * * * * * * * * * * *
  async setLocationsBulk(chargers = [], location = { loc: -1, bldg: -1, rack: -1 }) {
    return await Base.post(PostPaths.BULK_SET_LOCATION, { chargers, location });
  }

  async setLocation(devID, location) {
    return await Base.post(PostPaths.REGISTER_CHARGER, { devID, location });
  }

  async editCharger(chargerId, edit) {
    if (logIt) console.log("Update charger", chargerId, edit);
    const res = await Base.post(PostPaths.EDIT_CHARGER, { chargerId, edit });
    if (logIt) console.log("Updated charger response", res);
    return res
  }

  async setChargerPositions(newPositions) {
    return await Base.post(PostPaths.SET_CHARGER_POS, { newPositions });
  }
}
