import { cloneDeep } from "lodash";
import Struct from "./data/profile.json";

export default class ChargeProfile {
  constructor(profile) {
    Object.assign(this, Struct);
    if (profile) {
      Object.assign(this, profile);
      if (profile instanceof ChargeProfile) return;
    }
    // do extra stuff here (convert etc)
  }

  // Return DB-Fähig
  // eg remove subs
  toSaveable() {
    let saveable = cloneDeep(this);
    return saveable;
  }
}
