export default {
  namespaced: true,
  name: "messages",

  state: {
    messageQueue: [],
    messageHistory: [],
  },

  mutations: {
    clearMessages(state) {
      state.messageQueue = [];
    },

    popQueue(state) {
      state.messageQueue.shift();
    },

    sendError(state, message) {
      message.icon = message.icon || "mdi-alert-octagon-outline";
      state.messageQueue.push({ type: "error", ...message, ts: Date.now() });
      setTimeout(() => {
        let element = state.messageQueue.shift();
        state.messageHistory.unshift(element); // unshift = prepend new item to array
        // prevents date sorting + performance issues
      }, 2500);
    },

    sendWarning(state, message) {
      message.icon = message.icon || "mdi-alert-outline";
      state.messageQueue.push({ type: "warning", ...message, ts: Date.now() });
      setTimeout(() => {
        let element = state.messageQueue.shift();
        state.messageHistory.unshift(element);
      }, 2500);
    },

    sendInfo(state, message) {
      message.icon = message.icon || "mdi-information-box-outline";
      state.messageQueue.push({ type: "info", ...message, ts: Date.now() });
      setTimeout(() => {
        let element = state.messageQueue.shift();
        state.messageHistory.unshift(element);
      }, 2500);
    },

    sendSuccess(state, message) {
      message.icon = message.icon || "mdi-check-circle-outline";
      state.messageQueue.push({ type: "success", ...message, ts: Date.now() });
      setTimeout(() => {
        let element = state.messageQueue.shift();
        state.messageHistory.unshift(element);
      }, 2500);
    },
  },

  actions: {
    fetchErrors({ commit }) {
      commit

    }
  },

  getters: {
    getMessages: (state) => state.messageQueue,
    getHistory: (state) => state.messageHistory,
  },
};
