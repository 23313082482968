<template>
  <v-app :style="background" class="m-app" v-if="loggedIn && !isLoading">
    <app-bar v-if="!$store.state.hideAppbar" />
    <!-- <nav-drawer class="hidden-sm-and-down" v-if="!$store.state.hideDrawer" /> -->
    <v-main
      v-if="isOffline && !isSettingsPage"
      class="m-main d-flex flex-row justify-center"
    >
      <v-card
        class="d-flex flex-column justify-center align-center fill-height"
      >
        <v-card-title
          class="open-sans d-flex flex-column justify-center align-center text-h4 red--text"
        >
          <v-icon class="mb-4" x-large color="error"
            >mdi-server-network-off</v-icon
          >
          <h4 style="font-weight: 700">Backend Offline</h4>
        </v-card-title>
        <v-card-text class="d-flex flex-column justify-center align-center">
          <p style="text-align: center">
            Es konnte keine Verbindung mit dem Backend-Server aufgebaut werden.
            Stelle sicher, dass die
            <a href="/#/settings/" style="color: currentColor; font-weight: 700"
              >Backend-Adresse</a
            >
            korrekt konfiguriert wurde.
          </p>
          <p style="text-align: center">
            Sollte das Problem weiterhin bestehen bleiben, kontaktiere den
            technischen Support!
          </p>
          <v-btn @click="() => reloadWindow()" class="mt-4" outlined>
            <v-icon left>mdi-refresh</v-icon>
            Seite Neu Laden
          </v-btn>
        </v-card-text>
      </v-card>
    </v-main>

    <v-main v-else class="m-main d-flex flex-row justify-center">
      <router-view class="m-router-view" :key="$route.fullPath" />
    </v-main>

    <message-center />

    <v-snackbar :value="false">
      <div class="horizontal gapped centered">
        <v-progress-circular indeterminate>
          <v-icon small>mdi-bell</v-icon>
        </v-progress-circular>
        <span> Ringing bell </span>
        <v-spacer></v-spacer>
        <v-btn icon><v-icon>mdi-close</v-icon></v-btn>
      </div>
    </v-snackbar>

    <help-dialog />
    <user-dialog v-if="$store.state.showDialogUser" />
    <settings-dialog v-if="$store.state.showDialogSettings" />
    <setup-dialog v-if="$store.state.showSetupDialog" />
    <error-dialog v-if="$store.state.showErrorDialog" />
    <select-profile-dialog v-if="getDialogShow('selectProfile')" />
    <location-edit-dialog v-if="getDialogShow('locationEdit')" />
    <building-edit-dialog v-if="getDialogShow('buildingEdit')" />
    <rack-edit-dialog v-if="getDialogShow('rackEdit')" />
    <charger-edit-dialog v-if="getDialogShow('chargerEdit')" />
    <charge-mode-dialog v-if="getDialogShow('chargemodeConfig')" />
    <charge-profile-group-dialog v-if="getDialogShow('profileGroupConfig')" />
    <delete-dialog v-if="getDialogShow('deleteDialog')" />
  </v-app>

  <v-app v-else-if="isLoading" style="position: relative">
    <div class="vertical absolute center">
      <loading-view />
      {{ loadingInfo }}
    </div>
  </v-app>

  <v-app v-else>
    <v-dialog
      v-model="showLoginDial"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>Dev Login</v-card-title>
        <v-card-text>
          <v-text-field
            :color="$vuetify.theme.dark ? 'white' : 'black'"
            style="margin: 1rem"
            name="Passwort"
            label="Passwort"
            id="pin"
            v-model="devPin"
            :type="showPassword ? 'text' : 'password'"
            outlined
            @click:append="showPassword = !showPassword"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
// Views
import AppBar from "./views/components/root/AppBar.vue";
import MessageCenter from "./views/components/root/MessageCenter.vue";

// Dialogs
const HelpDialog = () => import("./views/dialogs/HelpDialog.vue");
const UserDialog = () => import("./views/dialogs/UserDialog.vue");
const SetupDialog = () => import("./views/dialogs/SetupDialog.vue");
const ErrorDialog = () => import("./views/dialogs/ErrorDialog.vue");
const SettingsDialog = () => import("./views/dialogs/SettingsDialog.vue");
const ChargeModeDialog = () => import("./views/dialogs/ChargeModeDialog.vue");
const SelectProfileDialog = () =>
  import("./views/dialogs/SelectProfileDialog.vue");
const LocationEditDialog = () =>
  import("./views/dialogs/LocationEditDialog.vue");
const BuildingEditDialog = () =>
  import("./views/dialogs/BuildingEditDialog.vue");
const RackEditDialog = () => import("./views/dialogs/RackEditDialog.vue");
const ChargerEditDialog = () => import("./views/dialogs/ChargerEditDialog.vue");

// Sonst
import { mapActions, mapGetters } from "vuex";
import LoadingView from "./views/components/root/LoadingView.vue";
import ChargeProfileGroupDialog from "./views/dialogs/ChargeProfileGroupDialog.vue";
import DeleteDialog from "./views/dialogs/DeleteDialog.vue";

export default {
  components: {
    AppBar,
    MessageCenter,
    LoadingView,
    // Dialogs
    HelpDialog,
    UserDialog,
    SettingsDialog,
    ChargeModeDialog,
    SetupDialog,
    ErrorDialog,
    SelectProfileDialog,
    LocationEditDialog,
    BuildingEditDialog,
    RackEditDialog,
    ChargerEditDialog,
    ChargeProfileGroupDialog,
    DeleteDialog,
  },

  data() {
    return {
      showLoginDial: true,
      isLoading: true,
      isOffline: false,
      loadingInfo: "",
      showPassword: false,
    };
  },

  created() {
    if (localStorage.getItem("devPin"))
      this.setDevPin(localStorage.getItem("devPin"));
    if (this.loggedIn) this.showLoginDial = false;
  },

  async mounted() {
    this.setWindow(window);

    this.loadingInfo = "Lade...";

    // TODO: get allCharger/batteries
    // with selected location/building
    await Promise.all([
      this.initConfig(),
      this.fetchAppData(),
      this.fetchUsers(),
      this.fetchChargeModes(),
      this.fetchChargeProfiles(),
      this.fetchErrors(),
      this.getUserFromLocalStorage(),
      this.fetchLocations(),
      this.fetchBuildings(),
      this.fetchRacks(),
      this.fetchAllChargers(),
      this.fetchBatteries(),
    ]);

    this.isLoading = false;
    this.loadingInfo = null;

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize, { passive: true });
    });
  },

  methods: {
    ...mapActions(["setWindow"]),
    ...mapActions("app", ["fetchAppData", "loadDeviceHistory"]),
    ...mapActions("user", ["fetchUsers", "getUserFromLocalStorage"]),
    ...mapActions("config", ["initConfig", "setDevPin"]),
    ...mapActions("chargemode", ["fetchChargeModes", "fetchChargeProfiles"]),
    ...mapActions("error", ["fetchErrors"]),

    ...mapActions("location", ["fetchLocations"]),
    ...mapActions("building", ["fetchBuildings"]),
    ...mapActions("rack", ["fetchRacks"]),
    ...mapActions("charger", ["fetchAllChargers"]),
    ...mapActions("battery", ["fetchBatteries"]),

    reloadWindow() {
      window.location.reload();
    },

    onResize() {
      this.setWindow(window);
    },
  },

  computed: {
    ...mapGetters(["getLoadings"]),
    ...mapGetters("charger", ["getChargers"]),
    ...mapGetters("dialog", ["getDialogShow"]),
    ...mapGetters("user", ["isAuthed"]),
    ...mapGetters("config", ["colorScheme", "loggedIn", "getDevPin"]),
    ...mapGetters("battery", ["getBatteriesActiveIDs"]),

    isSettingsPage() {
      return this.$route.path.startsWith("/settings");
    },

    devPin: {
      get() {
        return this.getDevPin;
      },
      set(value) {
        this.setDevPin(value);
      },
    },

    background() {
      return this.$vuetify.theme.dark
        ? "background: black"
        : "background: whitesmoke";
    },
  },

  watch: {
    colorScheme(value) {
      if (value) {
        this.$vuetify.theme.dark = value == "dark";
      } else {
        this.$vuetify.theme.dark = true;
      }
    },
  },
};
</script>

<style lang="scss">
// Basic styles für alle Vues
@import "@/assets/styles/basics.scss";
@import "@/assets/styles/anims.scss";

.theme--light.v-input--switch .v-input--switch__track {
  color: red;
}

html {
  overflow-y: auto !important;
}

#app {
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.m-app {
  width: 100vw;
}

.app-bar {
  z-index: 50 !important;
}

.nav-drawer {
  z-index: 50 !important;
}

.router-link {
  text-decoration: none !important;
}

.mcont {
  width: 100%;
  min-height: 90%;
  padding: 1rem 4% !important;

  @media screen and (max-width: 1000px) {
    padding: 8% 4% !important;
  }
}

*::-webkit-scrollbar {
  width: 0.5rem;
  height: 4px;
  background-color: transparent;
  position: fixed;
  right: 4px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background: var(--v-primary-base);
  border-radius: 4px;
}
</style>

<style lang="scss" scoped>
.m-main {
  padding: 0;
  margin: 0;
}
.m-content {
  padding: 0;
  margin: 0;
}
.m-router-view {
  padding: 0;
  margin: 0;
}
</style>
