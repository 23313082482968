import Vue from "vue";
import VueRouter from "vue-router";

// Instant Import
const DashboardPage = () => import("../views/pages/DashboardPage.vue");
const AdminDashPage = () =>
  import("../views/pages/admin/AdminDashboardPage.vue");
const DevPage = () => import("../views/pages/dev/DevPage.vue");

const ChargerInfoPage = () => import("../views/pages/FmChargerInfoPage.vue");
const SlotInfoPage = () => import("../views/pages/FmSlotInfoPage.vue");
const BatteryInfoPage = () => import("../views/pages/BatteryInfoPage.vue");
const SettingBasePage = () =>
  import("../views/pages/settings/SettingsBasePage.vue");
const SupportPage = () => import("../views/pages/SupportPage.vue");
const BatteryCyclePage = () => import("../views/pages/BatteryCyclePage.vue");
const LoginPage = () => import("../views/pages/LoginPage.vue");
// const LocationPage    = () =>  import('../views/pages/LocationPage.vue')

const ServerSettings = () =>
  import("../views/pages/settings/panes/ServerSettings.vue");
const UserSettings = () =>
  import("../views/pages/settings/panes/UserSettings.vue");
const AdminTools = () => import("../views/pages/settings/panes/AdminTools.vue");
const DeveloperTools = () =>
  import("../views/pages/settings/panes/DeveloperTools.vue");
const AboutPage = () => import("../views/pages/settings/panes/AboutPage.vue");
const DeviceManagement = () =>
  import("../views/pages/settings/panes/DeviceManagement.vue");
const ChargeModeSettings = () =>
  import("../views/pages/settings/panes/ChargeModeSettings.vue");

Vue.use(VueRouter);
//
import { vrouter } from "../../../../_res/config.json";

const routes = [
  // static Routes
  {
    ...vrouter.login,
    component: LoginPage,
  },
  {
    ...vrouter.dash,
    component: DashboardPage,
  },
  {
    ...vrouter.admin,
    component: AdminDashPage,
  },
  {
    ...vrouter.dev,
    component: DevPage,
  },

  // Dynamic Routes
  {
    ...vrouter.michrg,
    component: ChargerInfoPage,
  },
  {
    ...vrouter.battery,
    component: BatteryInfoPage,
  },
  {
    ...vrouter.slot,
    component: SlotInfoPage,
  },
  {
    ...vrouter.settings,
    component: SettingBasePage,
    children: [
      {
        path: "/",
        name: "ServerSettings",
        components: {
          settings: ServerSettings,
        },
      },
      {
        path: "/settings/user",
        name: "UserSettings",
        components: {
          settings: UserSettings,
        },
      },
      {
        path: "/settings/admin",
        name: "AdminTools",
        components: {
          settings: AdminTools,
        },
      },
      {
        path: "/settings/dev",
        name: "DeveloperTools",
        components: {
          settings: DeveloperTools,
        },
      },
      {
        path: "/settings/about",
        name: "AboutPage",
        components: {
          settings: AboutPage,
        },
      },
      {
        path: "/settings/admin/devices",
        name: "DeviceManagement",
        components: {
          settings: DeviceManagement,
        },
      },
      {
        path: "/settings/admin/chargemodes",
        name: "Charge-Modes",
        components: {
          settings: ChargeModeSettings,
        },
      },
    ],
  },
  {
    ...vrouter.support,
    component: SupportPage,
  },
  {
    ...vrouter.cycle,
    component: BatteryCyclePage,
  },
  // {
  //   path: '/location/:id',
  //   name: 'Location',
  //   component: LocationPage
  // },
];

const router = new VueRouter({
  routes,
});

export default router;
