<template>
  <v-sheet
    class="mb-4 py-2 px-4 rounded-lg d-flex flex-row justify-center"
    :color="message.type"
  >
    <v-icon class="mr-2" color="white">{{ message.icon }}</v-icon>
    <span>{{ message.text }}</span>
  </v-sheet>
</template>

<script>
import SystemMessageMixin from '../../../plugins/mixins/SystemMessageMixin'
export default {
  mixins: [SystemMessageMixin],
  props: ['message'],
  mounted() {

  }
}
</script>
