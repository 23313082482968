<template>
  <base-dialog dialKey="deleteDialog" :title="title" subtitle="Siiiiichaaa?">
    <template #actions>
      <v-btn depressed rounded @click="hideDial"> Abbruch </v-btn>

      <v-spacer />

      <v-btn
        class="horizontal gapped half centered-v"
        color="error"
        depressed
        rounded
        outlined
        @click="onSave"
      >
        <v-icon color="white">mdi-delete</v-icon>
        <span style="color: white"> Löschen </span>
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { dialogs } from "../../store/modules/dialogModule";
import BaseDialog from "./BaseDialog.vue";
export default {
  components: { BaseDialog },
  name: "DeleteDialog",

  data() {
    return {
      dialKey: dialogs.deleteDialog,
    };
  },

  computed: {
    ...mapGetters("dialog", [
      "getDialogShow",
      "getDialogData",
      "getDialogOnSave",
    ]),
    title() {
      const locTitle = this.getDialogData(this.dialKey).title;
      return `${locTitle} ${this.entry.name} löschen?`;
    },
    entry() {
      return this.getDialogData(this.dialKey).data;
    },
  },

  methods: {
    ...mapActions("dialog", ["showDialog", "hideDialog"]),
    hideDial() {
      this.hideDialog(this.dialKey);
    },
    onSave() {
      const on = this.getDialogOnSave(this.dialKey);
      console.log(on, this.entry);
      if (on) on(this.entry);
    },
  },
};
</script>

<style lang="scss" scoped></style>
