// configModule
// evtl merge with/into appModule
import miConfig from "../../miConfig";

// TODO: REMOVE
const pin = "akku-laden-2022";

export default {
  namespaced: true,
  name: "config",

  state: {
    server: miConfig.server.default,
    paths: miConfig.server.paths,

    colorScheme: "",
    enableAccessibility: false,

    devPin: "",

    graphsConfig: [ ...miConfig.workerInfos.graphs ],
  },

  mutations: {
    SET_COLOR_SCHEME(state, colorScheme) {
      state.colorScheme = colorScheme;
    },

    SET_ACCESSIBILITY_MODE(state, eAcc) {
      state.enableAccessibility = eAcc;
    },

    SET_SERVER(state, server) {
      state.server = server;
    },

    SET_DEV_PIN(state, pin) {
      state.devPin = pin;
    },

    // Graph
    SET_GRAPHS_CONFIG(state, graphsConfig) {
      state.graphsConfig = graphsConfig;
    },

    UPDATE_GRAPH_FIELD(state, updatedGraph) {
      const index = state.graphsConfig.findIndex(
        (graph) => graph.id === updatedGraph.id
      );
      if (index !== -1) {
        state.graphsConfig[ index ] = updatedGraph;
      } else {
        state.graphsConfig.push(updatedGraph);
      }
    },
  },

  actions: {
    setDevPin({ commit }, devPin) {
      commit("SET_DEV_PIN", devPin ?? "");
      if (devPin) localStorage.setItem("devPin", devPin);
    },

    initConfig({ commit, dispatch }) {
      const colorScheme = localStorage.getItem("color-scheme");
      if (colorScheme) {
        commit("SET_COLOR_SCHEME", colorScheme);
      }

      const accessibilityMode = localStorage.getItem("accessibility-mode");
      if (accessibilityMode)
        commit("SET_ACCESSIBILITY_MODE", accessibilityMode === "true");

      const server = localStorage.getItem("michrg-server");
      if (server) commit("SET_SERVER", server);

      dispatch("fetchGraphConfig");
    },

    setColorScheme({ commit }, colorScheme) {
      commit("SET_COLOR_SCHEME", colorScheme);
      localStorage.setItem("color-scheme", colorScheme);
    },

    setAccessibilityMode({ commit }, accessibilityMode) {
      commit("SET_ACCESSIBILITY_MODE", !!accessibilityMode);
      localStorage.setItem("accessibility-mode", String(accessibilityMode));
    },

    setServer({ commit }, server) {
      commit("SET_SERVER", server);
      localStorage.setItem("michrg-server", server);
    },

    // Slot Graphs Config
    async fetchGraphConfig({ dispatch }) {
      const baseConfig = [ ...miConfig.workerInfos.graphs ];

      // TODO: Get from DB.Userdata.graphs...
      const customConfig = JSON.parse(localStorage.getItem("graphsConfig"));
      if (!customConfig) return;

      const mergedConfig = baseConfig.map((baseItem) => {
        const customItem = customConfig.find((item) => item.id === baseItem.id); // Assuming each graph has a unique 'id'
        return customItem ? { ...baseItem, ...customItem } : baseItem;
      });

      dispatch("setGraphConfig", mergedConfig);
    },

    setGraphConfig({ commit }, config) {
      commit("SET_GRAPHS_CONFIG", config);
    },

    updateGraphConfig({ state, commit }, updatedGraphConfig) {
      commit("UPDATE_GRAPH_FIELD", updatedGraphConfig);
      localStorage.setItem("graphsConfig", JSON.stringify(state.graphsConfig));
    },
  },

  getters: {
    appStatus: (state) => state.status,
    getDevPin: (state) => state.devPin,

    server: (state) => state.server,
    colorScheme: (state) => state.colorScheme,
    enableAccessibility: (state) => state.enableAccessibility,

    loggedIn: (state) => state.devPin === pin,

    getGraphsConfig: (state) => state.graphsConfig,
  },
};
