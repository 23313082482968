import Struct from "./data/building.json";

export default class Building {
  constructor(building) {
    Object.assign(this, Struct);

    if (building) {
      Object.assign(this, building);
      if (building instanceof Building) return;
    }
  }
}
