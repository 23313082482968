// buildingModule.js
import dbHelper from "../../../db/DBHelper";
import Building from "../../../db/classes/building";
import { Logging } from "../../../miConfig";

const logIt = Logging.Building.STORE

export default {
  namespaced: true,
  name: "building",

  state: {
    buildings: [],
    currentBuilding: null,
  },

  mutations: {
    SET_BUILDINGS(state, buildings) {
      state.buildings = buildings.map(building =>
        building instanceof Building ? building : new Building(building)
      );
      if (logIt) {
        console.log("SET_BUILDINGS", state.buildings);
      }
    },

    SET_BUILDING(state, building) {
      if (!(building instanceof Building)) building = new Building(building);
      const index = state.buildings.findIndex(b => b.id === building.id);

      if (index === -1) {
        state.buildings.push(building);
      } else {
        state.buildings[ index ] = building;
      }

      if (logIt) {
        console.log("SET_BUILDING", building);
      }
    },

    UPDATE_BUILDING(state, building) {
      const index = state.buildings.findIndex(b => b.id === building.id);

      if (index !== -1) {
        building = new Building({
          ...state.buildings[ index ],
          ...building
        });
        state.buildings[ index ] = building;
      }

      if (logIt) {
        console.log("UPDATE_BUILDING", building);
      }
    },

    SET_CURRENT_BUILDING(state, building) {
      if (!(building instanceof Building)) building = new Building(building);
      state.currentBuilding = building;

      if (logIt) {
        console.log("SET_CURRENT_BUILDING", building);
      }
    },

    REMOVE_BUILDING(state, buildingId) {
      const index = state.buildings.findIndex(b => b.id === buildingId);
      if (index !== -1) {
        state.buildings.splice(index, 1);
      }

      if (logIt) {
        console.log("REMOVE_BUILDING", buildingId);
      }
    }
  },

  actions: {
    async fetchBuildings({ commit }) {
      let buildings = await dbHelper.Building.getBuildings();
      commit("SET_BUILDINGS", buildings);
    },

    async fetchBuildingById({ commit }, bldgId) {
      let building = await dbHelper.Building.getBuildingByID(bldgId);
      commit("SET_BUILDING", building);
    },

    async setCurrentBuildingID({ state, dispatch }, id) {
      dispatch("setCurrentBuilding", state.buildings[ id ]);

      // Refresh
      const building = await dbHelper.getBuildingByID(id);
      dispatch("setCurrentBuilding", building);
    },

    setCurrentBuilding({ commit }, building) {
      commit("SET_CURRENT_BUILDING", building);
    },

    async addBuilding({ commit }, building) {
      try {
        if (!building) return console.warn("Cannot add Building:", building);
        building = await dbHelper.Building.addBuilding(building)
        commit("SET_BUILDING", building)
      } catch (error) {
        console.error(error);
        // Dev
        building.id = Date.now()
        commit("SET_BUILDING", building)
      }
    },

    async updateBuilding({ commit }, { id, building }) {
      if (!building) return console.warn("Cannot update Building:", id, building);
      await dbHelper.Building.updateBuilding(id ? id : building.id, building)
      commit("UPDATE_BUILDING", building);
    },

    async removeBuilding({ commit }, { id }) {
      if (!id) return console.warn("Cannot remove BuildingID:", id);
      await dbHelper.Building.removeBuilding(id)
      commit("REMOVE_BUILDING", id)
    }
  },

  getters: {
    // All
    getBuildings: (state) => state.buildings,
    getBuildingIDs: (state) => state.buildings.map(building => building.id),
    getBuildingByID: (state) => (id) =>
      state.buildings.find(building => building.id === id),
    getBuildingsForLocationID: (state) => (id) =>
      state.buildings.filter(building => building.relation && building.relation.locId == id),

    // Current
    getCurrentBuilding: (state) => state.currentBuilding,

    // Bools
    hasBuildings: (_, getters) => getters.getBuildings.length > 0
  },
};
