import Struct from "./data/location.json";

export default class MiLocation {
  constructor(location) {
    Object.assign(this, Struct);
    if (location) {
      Object.assign(this, location);
      if (location instanceof MiLocation) return;
      this.id = this.locId
    }
  }
}
