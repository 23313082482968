import Vue from "vue";
import Vuex from "vuex";
import config from "../../../../_res/config.json";

// Modules
import appModule from "./modules/appModule";
import userModule from "./modules/userModule";
import errorModule from "./modules/errorModule";
import messageModule from "./modules/messagesModule";
import dialogModule from "./modules/dialogModule";
import configModule from "./modules/configModule";
import chargemodeModule from "./modules/chargemodeModule";

import locationModule from "./modules/types/locationModule";
import buildingModule from "./modules/types/buildingModule";
import rackModule from "./modules/types/rackModule";
import chargerModule from "./modules/types/chargerModule";
import slotModule from "./modules/types/slotModule";
import batteryModule from "./modules/types/batteryModule";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app: appModule,
    config: configModule,
    message: messageModule,
    error: errorModule,
    dialog: dialogModule,
    user: userModule,

    location: locationModule,
    building: buildingModule,
    charger: chargerModule,
    slot: slotModule,
    battery: batteryModule,
    rack: rackModule,
    chargemode: chargemodeModule,
  },

  state: {
    config: {},
    devPin: null,

    // tmp ?
    cachedSlot: undefined,

    chargeModes: [ ...config.defaultChargeModes ],

    window: {
      width: null,
      height: null,
    },

    isMobile: false,

    deviceHistory: [],
    selectedChargers: [],

    /* temp */
    chargerLocUpdated: false,
    enableAccessibility: false,
    enableChargerDrag: false,
    chargerLocations: [],

    userObject: undefined,

    chargerCache: [],

    // Show/Hide Helpers
    hideDrawer: false,
    hideAppbar: false,
    showFilter: false,

    // Dialog Helpers
    showDialogHelp: false,
    showDialogUser: false,
    showDialogSettings: false,
    showSetupDialog: false,
    showErrorDialog: false,

    showNewUserDialog: false,

    showUserMenu: false,

    // Users, Profiles, etc
    server: null,
    currentUser: null,
    defaultUser: {
      _id: "-1",
      name: "Gast",
      role: 0,
      authLevel: 0,
      initials: "G",
      lastSeen: new Date().getTime(),
    },

    activeGraphKey: "socr",
    activeGraphName: "Batterie-Level",

    users: [
      {
        key: "DGE",
        name: "Dennis Grosse",
        role: 0,
        icon: "mdi-chess-king",
        lastSeen: new Date().getTime(),
      },
      {
        key: "FHT",
        name: "Franko Heckert",
        role: 0,
        icon: "mdi-code-array",
        lastSeen: new Date().getTime(),
      },
      {
        key: "DGF",
        name: "Dirk Graf",
        role: 0,
        icon: "mdi-castle",
        lastSeen: new Date().getTime(),
      },
      {
        key: "GST",
        name: "Gast",
        role: 3,
        icon: "mdi-account",
        lastSeen: new Date(Date.now() - 1231230),
      },
    ],

    //Selected Things
    currentLocation: null,
    currentBuilding: null,
    currentRack: null,

    // TODO: Remove ???
    filter: {
      chargemode: null,
      status: null,
    },

    /* Workaround to Router Issue */
    tmpBattery: undefined,

    locations: [],
    buildings: [],
    racks: [],
    errors: [],

    profile: {
      locations: [],
      buildings: [],
      racks: [],
      chargers: [],
    },
  },

  mutations: {
    setWindowSize(state, sizes) {
      state.window = sizes;
    },

    currentUser(state, user) {
      state.currentUser = user;
    },

    currentLocation(state, item) {
      const childBuilding = state.currentBuilding;

      if (childBuilding && item != undefined) {
        const foundBldg = item.buildings.find((x) => childBuilding.bldgId == x);
        if (!foundBldg) state.currentBuilding = undefined;
      }

      state.currentLocation = item;
    },

    currentBuilding(state, item) {
      if (item) {
        const parentLocation = state.locations.find((loc) => {
          return String(loc.locId) == String(item.relation.locId);
        });

        const { currentLocation } = state;
        const curLocId = currentLocation ? currentLocation.locId : -1;

        if (parentLocation.locId != curLocId) state.currentLocation = undefined;
      }

      state.currentBuilding = item;
    },

    currentRack(state, item) {
      state.currentRack = item;
    },
  },

  actions: {
    setWindow({ commit }, window) {
      commit("setWindowSize", {
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
  },

  methods: {
    hasFilter() {
      return this.chargemode > -1 && this.status > -1;
    },
  },

  getters: {
    getSizes: (state) => state.window,
    isMobile: (state) => state.innerWidth < 600,
    flexDirection: (state) =>
      state.isMobile ? "d-flex flex-column" : "d-flex flex-row align-stretch",

    getLoadings: (state, getters) => {
      const modulesToCheck = [ "charger" ];
      return modulesToCheck.reduce((result, module) => {
        const loadingState = getters[ `${module}/getLoading` ];
        if (Object.keys(loadingState).length > 0) {
          result[ module ] = loadingState;
        }
        return result;
      }, {});
    },
  },
});
