// TODO: Struct überarbeiten
import { cloneDeep } from "lodash";
import Struct from "./data/slot.json";
import { ChargerCommands, setSlotCharge } from "../dbModules/ChargerCommands";

export default class Slot extends ChargerCommands {

  constructor(slot, charger) {
    super(charger)
    this.chId = charger.id;
    Object.assign(this, Struct);
    if (slot) {
      Object.assign(this, slot);
      this.id = slot._id
      delete slot._id


      // TODO REMOVE when ready
      if (this.battery == 0) this.battery = null;

      if (this.last_data) this.lastData = this.last_data;
      delete this.last_data;
    }
  }

  async setChargeMode(mode, disableCmd) {
    const isSame = this.chargeMode == mode.id || mode == null
    if (typeof mode === 'object' && mode !== null) {
      this.chargeMode = mode.id;
    } else {
      this.chargeMode = mode;
    }
    if (!isSame && !disableCmd)
      return await setSlotCharge(this.chId, this.slot, mode)
  }


  get percentCharged() {
    if (!this.lastData.socr) return "0";
    return this.lastData.socr;
  }

  // Return DB-Fähig
  // eg remove subs -> toID
  toSaveable() {
    let saveableSlot = cloneDeep(this);
    return saveableSlot;
  }
}
