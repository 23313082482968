export const dialogs = {
  dialogHelp: "dialogHelp",
  dialogUser: "dialogUser",
  dialogSettings: "dialogSettings",
  setupDialog: "setupDialog",
  errorDialog: "errorDialog",
  newUserDialog: "newUserDialog",
  userMenu: "userMenu",
  selectProfile: "selectProfile",
  deleteDialog: "deleteDialog",

  // ChargeTypes
  chargemodeConfig: "chargemodeConfig",
  profileGroupConfig: "profileGroupConfig",

  // Locations
  locationInfo: "locationInfo",
  locationEdit: "locationEdit",

  // Buildings
  buildingInfo: "buildingInfo",
  buildingEdit: "buildingEdit",

  // Racks
  rackInfo: "rackInfo",
  rackEdit: "rackEdit",

  // Charger
  chargerEdit: "chargerEdit", //TODO: Name, Location etc
};

const state = {};

for (const key in dialogs) {
  state[ key ] = {
    show: false,
    data: null,
    onSave: null,
    lastSelected: {},
  };
}

export default {
  namespaced: true,
  name: "dialog",

  state,

  mutations: {
    SET_DIALOG_STATE(state, { name, show, data, onSave }) {
      if (state[ name ]) {
        state[ name ].show = show;
        state[ name ].data = data;
        state[ name ].onSave = onSave;
      } else {
        console.error(`Invalid dialog name: ${name}`);
      }
    },
    SET_LAST_SELECTED(state, { name, key, value }) {
      state[ name ].lastSelected[ key ] = value;
    },
  },

  actions: {
    showDialog({ commit }, { name, data, onSave }) {
      commit("SET_DIALOG_STATE", { name, show: true, data, onSave });
    },

    hideDialog({ commit }, name) {
      commit("SET_DIALOG_STATE", { name, show: false, data: null });
    },

    setDialog({ commit }, { name, show, data }) {
      commit("SET_DIALOG_STATE", { name, show, data });
    },

    setLastSelected({ commit }, { name, key, value }) {
      commit("SET_LAST_SELECTED", { name, key, value });
    },
  },

  getters: {
    getDialog: (state) => (name) => state[ name ],
    getDialogShow: (state) => (name) => state[ name ].show || false,
    getDialogData: (state) => (name) => state[ name ].data || false,

    // Should return success boolean
    getDialogOnSave: (state) => (name) => {
      return state[ name ] && state[ name ].onSave
        ? state[ name ].onSave
        : () => true;
    },

    // LastSelected like "locId" in editCharger etc
    getDialogLastSelectedEntries: (state) => (name) => {
      return state[ name ].lastSelected || {};
    },
  },
};
