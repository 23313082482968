import miConfig from "../../../_res/config.json";
export default miConfig;

export const isOffice = false;
export const IS_DEV = process.env.NODE_ENV !== "production";

// Only Dev
// make PascalCase.CAPITAL
export const Logging = {
    db: false,
    ws: false,
    charger: {
        COMMANDS: false,
        STORE: false,
        CLASS: false,
        db: false
    },
    slot: {
        STORE: false,
        class: false,
        db: false
    },
    battery: {
        STORE: false,
        class: false,
        db: false
    },

    // like this
    Building: {
        STORE: false,
    },
    Rack: {
        STORE: false
    },
    ChargeModes: {
        STORE: false,
    },
    User: {
        STORE: false,
    },
    Location: {
        STORE: false,
        CLASS: false,
        DB: false
    }
}

// ChargeTypes 
export const ChargeTypes = {
    MODE: 1,
    PROFILE: 2,
    PROFILE_GROUP: 3,
    CUSTOM: 4,
};

export const MIN_CHARGE = 100;
export const MAX_CHARGE = 3500;

export const LocalStorageKeys = {
    locations: "locs",
    sections: "sections",
    racks: "racks",
}

export const ChargerDragGroup = "charger-group"

// Loadings, Errors
export const PageStatus = {
    Charger: {
        UPDATE: "update",
        SET_MODE: "mode",
        SET_PROFILE: "profile",
        SAVE_PROFILE: "saveProfile",
        SAVE_PROFILE_GROUP: "saveProfileGroup",
    }
}

// 
export const Commands = {
    SET_CURR_GLOBAL: "setcurrentglob",
    SET_CURR_SLOT: "setcurrent",

    SET_MODE: "setmode",
    SET_PROFILE: "setprofile",
    SET_PROFILE_GROUP: "setprofilegroup",

    ALERT: "alert",
    RESET: "reset",
    SHUT_OFF: "shut_off"
}

// Server
export let SERVER_ADDRESS = IS_DEV
    ? isOffice
        ? miConfig.server.office
        : miConfig.server.default
    : miConfig.server.production;

export let WS_ADDRESS = IS_DEV
    ? isOffice
        ? miConfig.server.wsOffice
        : miConfig.server.wsAddress
    : miConfig.server.wsProduction;

console.log("Server", SERVER_ADDRESS, WS_ADDRESS);
export let USER_TOKEN = localStorage.getItem("auth_token");

export const PATH_BRANCH = miConfig.server.paths;

export const GetPaths = {
    ALL_BATTERIES: PATH_BRANCH.all.batteries,
    ALL_BUILDINGS: PATH_BRANCH.all.buildings,
    ALL_CHARGERS: PATH_BRANCH.all.chargers,
    ALL_CHARGEMODES: PATH_BRANCH.all.chargeModes,
    ALL_LOCATIONS: PATH_BRANCH.all.locations,
    ALL_MCG_PROFILES: PATH_BRANCH.all.chargerProfiles,
    ALL_LOGS: PATH_BRANCH.all.logs,
    ALL_RACKS: PATH_BRANCH.all.racks,
    ALL_USERS: PATH_BRANCH.all.users,

    ABOUT: PATH_BRANCH.misc.about,
    BATTERY: PATH_BRANCH.individual.battery,
    BUILDING: PATH_BRANCH.individual.building,
    BTRY_CYCLES: PATH_BRANCH.individual.batteryCycles,
    CHARGER: PATH_BRANCH.individual.charger,
    LOCATION: PATH_BRANCH.individual.location,
    RACK: PATH_BRANCH.individual.rack,
    SEARCH: PATH_BRANCH.misc.search,
    STATS: PATH_BRANCH.misc.stats,
};

export const PostPaths = {
    // User
    CREATE_USER: PATH_BRANCH.post.createUser,

    // Charger
    EDIT_CHARGER: PATH_BRANCH.post.editCharger,
    SET_CHARGER_POS: PATH_BRANCH.post.setChargerPositions,
    REGISTER_CHARGER: PATH_BRANCH.post.registerCharger,
    SEND_MCG_COMMAND: PATH_BRANCH.post.sendChargerCommand,
    REQUEST_FIRMWARE: PATH_BRANCH.post.requestFirmwareUpdate,
    BULK_SET_LOCATION: PATH_BRANCH.post.bulkSetLocation,
    EDIT_CHARGER_PROFILE: PATH_BRANCH.post.editChargerProfile,

    // Battery
    EDIT_BATTERY: PATH_BRANCH.post.editBattery,

    // Location
    ADD_LOCATION: PATH_BRANCH.post.addLocation,
    EDIT_LOCATION: PATH_BRANCH.post.editLocation,
    DELETE_LOCATION: PATH_BRANCH.post.deleteLocation,

    // Building
    ADD_BUILDING: PATH_BRANCH.post.addBuilding,
    EDIT_BUILDING: PATH_BRANCH.post.editBuilding,
    DELETE_BUILDING: PATH_BRANCH.post.deleteBuilding,

    // Rack
    ADD_RACK: PATH_BRANCH.post.addRack,
    EDIT_RACK: PATH_BRANCH.post.editRack,
    DELETE_RACK: PATH_BRANCH.post.deleteRack,

    //ChargeType
    ADD_PROFILE: PATH_BRANCH.post.addChargeProfile
};
