import { render, staticRenderFns } from "./DeleteDialog.vue?vue&type=template&id=563a07c2&scoped=true&"
import script from "./DeleteDialog.vue?vue&type=script&lang=js&"
export * from "./DeleteDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563a07c2",
  null
  
)

export default component.exports