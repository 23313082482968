import miConfig from "../miConfig";

const chargeModeIcons = Object.values(miConfig.chargemodes).reduce(
  (icons, mode) => {
    icons[ mode.name ] = mode.icon;
    return icons;
  },
  {}
);

export default {
  location: "mdi-map-marker",
  building: "mdi-selection-marker",
  rack: "mdi-server",
  charger: "mdi-power-plug",
  battery: "mdi-battery-80",

  ...chargeModeIcons,
};
