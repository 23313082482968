import dbHelper from "../../db/DBHelper";
import { Logging } from "../../miConfig";

const guest = {
  _id: "-1",
  name: "Gast",
  role: 0,
  authLevel: 0,
  initials: "G",
  lastSeen: new Date().getTime(),
};

const logIt = Logging.User.STORE;

export default {
  namespaced: true,
  name: "user",

  state: {
    currentUser: guest,
    userToken: null,
    // TODO: Get from DB
    users: [],
  },

  mutations: {
    SET_USERS(state, users) {
      state.users = users
      if (logIt) console.log("SET_USERS", users);
    },

    SET_CURRENT_USER(state, { id, userData }) {
      // ???
      const user = state.users.find(it => it.id == id)
      state.currentUser = {
        ...user,
        ...userData
      }
    },

    SET_USER_TOKEN(state, token) {
      state.userToken = token
    }
  },

  actions: {
    // Fetch
    async fetchUsers({ commit }) {
      const users = await dbHelper.User.getUsers()
      if (users)
        commit("SET_USERS", users)
    },

    // Current
    async setCurrentUser({ commit }, id) {
      const userData = await dbHelper.User.getUserData(id)
      if (userData)
        commit("SET_CURRENT_USER", { id, userData })
    },

    getUserFromLocalStorage({ commit }) {
      if (localStorage.getItem("auth_token")) {
        commit("SET_USER_TOKEN", localStorage.getItem("auth_token"));
      }
    },
    setUserToken({ commit }, token) {
      if (!token) {
        return
      }
      commit("SET_USER_TOKEN", token);
    }
  },

  getters: {
    getUserToken: (state) => state.userToken,
    getCurrentUser: (state) => state.currentUser,

    isAuthed: () => true,
    // state.currentUser && state.currentUser.authLevel > 0,
    isLeiter: () => true,
    // 
    isAdmin: () => true,
    // state.currentUser && state.currentUser.authLevel > 0,
  },
};
