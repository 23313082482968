import Struct from "./data/chargeMode.json";

export default class ChargeMode {
  constructor(chargeMode) {
    Object.assign(this, Struct);
    if (chargeMode) {
      Object.assign(this, chargeMode);
      if (chargeMode instanceof ChargeMode) return;
    }
  }
}
