// TODO: Struct überarbeiten
import { cloneDeep } from "lodash";
import Struct from "./data/charger.json";
import Slot from "./slot";
import Store from "../../store/index";
import { ChargeTypes, Logging } from "../../miConfig";
import { ChargerCommands } from "../dbModules/ChargerCommands";

const logIt = Logging.charger.CLASS

// Dont like getters in class. Hmm
function getProfileFromStore(profileID) {
  const profile = Store.getters[ "chargemode/getChargeProfileByID" ](profileID);
  return profile;
}

export default class Charger extends ChargerCommands {
  chargeType = null;
  chargeModeID = null;
  chargeProfile = null;

  batteries = [];
  averageTemp = 0;

  constructor(charger) {
    super()
    Object.assign(this, Struct);
    if (charger) {
      Object.assign(this, charger);
      if (logIt) { console.log("CREATE CHARGER", charger); }
      if (!charger.id) {
        this.id = charger.meta.devID;
      }
      if (charger instanceof Charger) return;
    }
    this.setSlots(charger.slots)
  }

  // TODO: Better setSlots... (from DB)
  setSlots(slots) {
    let currentTotal = 0;
    let tempTotal = 0;
    let slotsWithTempCount = 0;

    this.slots = slots.map((it) => {
      // BAD
      const slot = new Slot(it, this);

      if (slot.battery) {
        this.batteries.push(slot.battery);
      }

      currentTotal += slot.lastData.curr || 0;

      if (slot.lastData?.temp) {
        tempTotal += slot.lastData.temp;
        slotsWithTempCount++;
      }

      return slot;
    });

    let averageTemp;
    if (slotsWithTempCount > 0) {
      const averageTempInt = tempTotal / slotsWithTempCount;
      averageTemp = Math.round(averageTempInt / 100);
    }

    this.averageTemp = averageTemp
    this.currentTotal = currentTotal;
  }

  // getters
  get isUnregistered() {
    if (!this.relation) return true;

    return (
      this.relation.locId == null ||
      this.relation.locId == -1 ||
      this.relation.bldgId == null ||
      this.relation.bldgId == -1 ||
      this.relation.rackId == null ||
      this.relation.rackId == -1
    );
  }

  get isMode() {
    return this.chargeType === ChargeTypes.MODE
  }

  get isProfile() {
    return this.chargeType === ChargeTypes.PROFILE
  }

  // Modes
  /**
   * ChargeType
   * Sets and apply the current ChargeType (mode, profile or custom).
   * Charger has saved (last used) modeId | profileId. 
   * So setChargeType(ChargeType.profile) sets the last used profileId
   * 
   * @param chargeType ChargeType. +  "mode" | "profile" | "custom"  
   */
  async setChargeType(chargeType, disableCmd) {
    this.chargeType = chargeType;
    if (logIt) console.log("Applying charge type", this.chargeType);
    if (disableCmd)
      return;
    return await this.#applyCharge()
  }

  async setChargeMode(mode, disableCmd, forceApply) {
    if (!forceApply && this.#isSameMode(mode.id)) {
      return console.warn("Still the same (Mode)");
    }
    this.chargeModeID = getIdCheckObj(mode);
    return await this.setChargeType(ChargeTypes.MODE, disableCmd);
  }

  async setChargeProfile(profile, disableCmd, forceApply) {
    if (!forceApply && this.#isSameProfile(profile.id)) {
      return console.warn("Still the same (Profile)");
    }
    this.chargeProfileID = getIdCheckObj(profile)
    return await this.setChargeType(ChargeTypes.PROFILE, disableCmd);
  }

  async setSlotMode(slotIndex, mode) {
    const slot = this.slots[ slotIndex ];
    slot.setChargeMode(mode, true)
    this.setChargeType(ChargeTypes.CUSTOM)
    const res = await this.setSlotCharge(slotIndex, mode)
    return res
  }

  setChargerCurrent(current) {
    current;
    console.log("TODO: Charger setChargerCurrent()");
  }

  #isSameMode(id) {
    return this.chargeModeID == id && this.isMode;
  }

  #isSameProfile(id) {
    return this.chargeProfileID == id && this.isProfile;
  }

  async #applyCharge() {
    if (this.chargeType == ChargeTypes.custom) return;

    const isTypeMode = this.chargeType === ChargeTypes.MODE;
    if (isTypeMode) {
      if (logIt) console.log("Charger: Applying Mode", this.chargeModeID);
      return await this.#applyMode()
    }

    const isTypeProfile = this.chargeType === ChargeTypes.PROFILE;
    if (isTypeProfile) {
      if (logIt) console.log("Charger: Applying Profile", this.chargeProfileID);
      return await this.#applyProfile()
    }
  }

  async #applyMode() {
    const hasMode = this.chargeModeID || this.chargeModeID === 0;
    if (!hasMode)
      return console.warn("No chargemode for charger");

    const result = super.setChargeMode(this.chargeModeID)

    // Remove Slot forEach and get from listener?
    this.slots.forEach((slot) => {
      slot.setChargeMode(this.chargeModeID, true);

    })
    return await result
  }

  async #applyProfile() {

    const hasProfile = this.chargeProfileID || this.chargeProfileID === 0;
    if (!hasProfile)
      return console.warn("No profile for charger");

    const result = super.setChargeProfile(this.chargeProfileID)

    // Remove Slot forEach and get from listener?
    const chargeProfile = getProfileFromStore(this.chargeProfileID);
    if (chargeProfile)
      this.slots.forEach((slot, index) => {
        const slotMode = chargeProfile.slots[ index ]
        slot.setChargeMode(slotMode, true);
      });

    return await result
  }

  // Return DB-Fähig
  // e.g. remove props, obj to ids, etc
  toSaveable() {
    let saveable = cloneDeep(this);
    delete saveable.batteries;
    delete saveable.averageTemp;
    // Bsp
    // this.slots.battery = this.slots.map((it) => it.toSaveable());
    return saveable;
  }
}

function getIdCheckObj(item) {
  if (typeof item === 'object' && item !== null) {
    return item.id;
  } else {
    return item;
  }
}
