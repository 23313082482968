import { cloneDeep } from "lodash";
import Struct from "./data/profileGroup.json";

export default class ChargeProfileGroup {
  constructor(profileGroup) {
    Object.assign(this, Struct);
    if (profileGroup) {
      Object.assign(this, profileGroup);
      if (profileGroup instanceof ChargeProfileGroup) return;
    }
  }

  // Return DB-Fähig
  // eg remove subs
  toSaveable() {
    let saveable = cloneDeep(this);
    return saveable;
  }
}

export const WeekdayExample = {
  id: 1,
  name: "Morning Profile",
  timeframe: {
    days: [ 1, 2, 3, 4, 5 ],
    start: "06:00",
    end: "18:00"
  },
  mode: 1,
  profileId: null
}

export function isWithinProfileGroup(profileGroup) {
  const now = new Date();

  // Check if today is within the specified days
  const today = now.getDay();  // This will return a number between 0 (Sunday) and 6 (Saturday)

  if (!profileGroup.timeframe.days.includes(today)) return false;

  // Check if the current time is within the specified timeframe
  const currentTime = now.getHours() + ":" + String(now.getMinutes()).padStart(2, '0');
  return currentTime >= profileGroup.timeframe.start && currentTime <= profileGroup.timeframe.end;
}
