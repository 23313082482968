import Vue from "vue";
import dbHelper from "../../../db/DBHelper";
import Charger from "../../../db/classes/charger";
import { PageStatus, ChargeTypes, Logging } from "../../../miConfig";

// I dont like it
const Status = PageStatus.Charger

// Debugi
const logIt = Logging.charger.STORE;

// Loading/Error keys
const loading = {};
const error = {};
for (const key of Object.values(Status)) {
  loading[ key ] = false;
  error[ key ] = null;
}

export default {
  namespaced: true,
  name: "charger",

  state: {
    // All
    chargers: [],
    history: JSON.parse(localStorage.getItem("ch-history")) || [],
    listener: null,
    currentChargerId: null,

    loading,
    error,
  },

  mutations: {
    SET_CHARGERS(state, chargers) {
      state.chargers = chargers.map(charger =>
        charger instanceof Charger ? charger : new Charger(charger)
      );

      if (logIt) {
        console.log("Got Chargers", state.chargers);
      }
    },

    SET_CHARGER(state, charger) {
      if (logIt) console.log('SET_CHARGER', charger);

      if (charger && !(charger instanceof Charger))
        charger = new Charger(charger);

      const index = state.chargers.findIndex(c => c.id === charger.id);
      if (index !== -1) {
        state.chargers[ index ] = charger;
      } else {
        state.chargers.push(charger);
      }

      if (logIt) {
        console.log("SET CHARGER", charger);
      }
    },

    UPDATE_CHARGER(state, charger) {
      if (logIt) console.log('UPDATE_CHARGER', charger);
      const existingCharger = state.chargers.find(c => c.id === charger.id);
      if (existingCharger) {
        const index = state.chargers.findIndex(c => c.id === charger.id);
        Vue.set(
          state.chargers, index, new Charger({ ...existingCharger, ...charger })
        )
      } else console.warn("Cannot update charger", charger);
    },

    REMOVE_CHARGER(state, chargerId) {
      if (logIt) console.log('REMOVE_CHARGER', chargerId);
      const index = state.chargers.findIndex(c => c.id === chargerId);
      if (index !== -1) {
        state.chargers.splice(index, 1);
      }
    },

    SET_CURRENT_CHARGER(state, charger) {
      if (logIt) console.log('SET_CURRENT_CHARGER', charger);
      state.currentChargerId = charger.id;
    },

    ADD_TO_HISTORY(state, chargerId) {
      const index = state.history.indexOf(chargerId);
      if (index !== -1) {
        state.history.splice(index, 1);
      }
      state.history.push(chargerId);
    },

    REMOVE_FROM_HISTORY(state, chargerId) {
      const index = state.history.indexOf(chargerId);
      if (index !== -1) {
        state.history.splice(index, 1);
      }
    },

    SET_LOADING(state, { key, value }) {
      Vue.set(state.loading, key, value)
      if (logIt) console.log('SET_LOADING', { key, value }, state.loading);
    },

    SET_ERROR(state, { key, value, action }) {
      if (logIt) console.log('SET_ERROR', { key, value, action });
      Vue.set(state.error, key, { value, action })
    },

    RESET_CHARGER_STATE(state) {
      if (logIt) console.log('RESET_CHARGER_STATE');
      state.currentChargerId = null
    },

    CLEAR_ERRORS(state) {
      for (const key of Object.values(Status)) {
        state.error[ key ] = null;
      }
    }
  },

  actions: {
    async fetchAllChargers({ commit, dispatch }) {
      let chargers = await dbHelper.Charger.getChargers()
      commit("SET_CHARGERS", chargers);

      const allSlots = chargers.reduce((acc, charger) => acc.concat(charger.slots), []);
      dispatch("slot/setSlots", allSlots, { root: true })

      return chargers;
    },

    // Current:
    // setID: listenTo Charger
    // onFound -> setCurrentCharger
    async setCurrentChargerID({ state, commit, getters, dispatch }, chargerID) {
      if (state.listener) state.listener();
      commit("CLEAR_ERRORS")

      // State
      let charger = getters.getChargerByID(chargerID);
      if (charger) charger = new Charger(charger)
      commit("SET_CURRENT_CHARGER", charger)
      dispatch("addIdToHistory", chargerID)

      state.listener = dbHelper.Charger.listenTo(
        chargerID,
        found => {
          if (found)
            // commit("UPDATE_CHARGER", found);
            found
        },
        err => {
          console.error(err);
        }
      )
    },

    addIdToHistory({ state, commit }, chargerID) {
      commit("ADD_TO_HISTORY", chargerID)
      localStorage.setItem("ch-history", JSON.stringify(state.history))
    },

    removeFromChargerHistory({ commit }, chargerId) {
      commit('REMOVE_FROM_HISTORY', chargerId);
    },

    async resetCurrentCharger({ state, commit }) {
      if (state.listener) state.listener();
      commit("CLEAR_ERRORS")
      commit("RESET_CHARGER_STATE");
    },

    // Funs#
    async setChargeType({ commit, getters }, { chargerId, type }) {
      commit("CLEAR_ERRORS")

      if (!type || !chargerId) {
        return console.warn("Cant set type without type or charger");
      }
      const key = type == ChargeTypes.MODE ? Status.SET_MODE : Status.SET_PROFILE
      commit("SET_LOADING", { key, value: true })

      const charger = getters.getChargerByID(chargerId)
      try {
        const res = await charger.setChargeType(type)
        if (res.msg) throw res.msg
      } catch (error) {
        commit("SET_ERROR", { key, value: error })
      } finally {
        commit("SET_LOADING", { key, value: false })
      }
    },

    async setChargeMode({ commit, getters, dispatch }, { chargeMode, chargerId, force }) {
      commit("CLEAR_ERRORS")
      if (!chargeMode || !chargerId) {
        return console.warn("Cant set mode without mode or charger");
      }
      commit("SET_LOADING", { key: Status.SET_MODE, value: true })
      const charger = getters.getChargerByID(chargerId)
      try {
        const res = await charger.setChargeMode(chargeMode, false, force)
        if (res.msg) throw res.msg
      } catch (error) {
        console.error(error);
        const action = () => dispatch("setChargeMode", { chargerId, chargeMode, force: true })
        commit("SET_ERROR", { key: Status.SET_MODE, value: error, action })
      } finally {
        commit("SET_LOADING", { key: Status.SET_MODE, value: false })
      }
    },

    async setChargeProfile({ commit, getters, dispatch }, { profile, chargerId, force }) {
      commit("CLEAR_ERRORS")
      if (!profile || !chargerId) {
        return console.warn("Cant set profile without profile or charger");
      }
      commit("SET_LOADING", {
        key: Status.SET_PROFILE,
        value: true
      })
      let charger = getters.getChargerByID(chargerId)
      try {
        await charger.setChargeProfile(profile, false, force)
      } catch (error) {
        console.error(error);
        const action = () => dispatch("setChargeProfile", { profile, chargerId, force: true })
        commit("SET_ERROR", { key: Status.SET_PROFILE, value: error, action })
      } finally {
        commit("SET_LOADING", { key: Status.SET_PROFILE, value: false })
      }
    },

    clearErrors({ commit }) {
      commit("CLEAR_ERRORS")
    },

    async updateCharger({ commit }, charger) {
      commit("SET_ERROR", { key: Status.UPDATE, value: false });
      commit("SET_LOADING", { key: Status.UPDATE, value: true });
      try {
        await dbHelper.Charger.editCharger(charger.id, charger);
        commit("UPDATE_CHARGER", charger);
      } catch (error) {
        commit("SET_ERROR", { key: Status.UPDATE, value: error });
      }
      commit("SET_LOADING", { key: Status.UPDATE, value: false });
    },
  },

  getters: {
    getChargers: (state) => state.chargers,
    getChargerHistory: (state, getters) => {
      return state.history.map(it => getters.getChargerByID(it)).reverse()
    },
    getChargerByID: (state) => (id) =>
      state.chargers.find(charger => charger.id === id),

    getUnregisteredChargers: (state) =>
      state.chargers.filter(charger => charger.isUnregistered),
    hasUnregisteredChargers: (_, getters) =>
      getters.getUnregisteredChargers.length > 0,

    getChargersWithWarnings: (state) =>
      state.chargers.filter(charger => charger.hasWarning),
    getChargersWithErrors: (state) =>
      state.chargers.filter(charger => charger.hasError),

    getCurrentChargerID: (state) => state.currentChargerId,
    getCurrentCharger: (state, getters) =>
      getters.getChargerByID(state.currentChargerId),
    getCurrentSlots: (_, getters) => getters.getCurrentCharger?.slots || [],


    getCurrentChargerName: (_, getters) => getters.getCurrentCharger?.name,
    getCurrentStatus: (_, getters) => getters.getCurrentCharger?.status,
    getCurrentLocation: (_, getters, __, rootGetters) =>
      rootGetters[ "location/getLocationByID" ](getters.getCurrentCharger?.relation.locId),
    getCurrentBuilding: (state, getters, rootState, rootGetters) =>
      rootGetters[ "building/getBuildingByID" ](getters.getCurrentCharger?.relation.bldgId),
    getCurrentChargeMode: (_, getters, __, rootGetters) => {
      const chargeModeID = getters.getCurrentCharger?.chargeModeID ?? 1;
      return rootGetters[ "chargemode/getChargeModeByID" ](chargeModeID);
    },
    getCurrentChargeProfile: (_, getters, __, rootGetters) => {
      const chargerProfileId = getters.getCurrentCharger?.chargeProfileID;
      return rootGetters[ "chargemode/getChargeProfileByID" ](chargerProfileId);
    },

    isMode: (_, getters) => getters.getCurrentCharger?.chargeType == ChargeTypes.MODE,
    isCustom: (_, getters) => getters.getCurrentCharger?.chargeType == ChargeTypes.CUSTOM,
    isProfile: (_, getters) => getters.getCurrentCharger?.chargeType == ChargeTypes.PROFILE,
    isProfileGroup: (_, getters) =>
      getters.getCurrentCharger?.chargeType == ChargeTypes.PROFILE_GROUP,

    getTable: (_, getters) => {
      return [
        { title: "Name", value: getters.getCurrentCharger?.name || "--" },
        { title: "ID", value: getters.getCurrentCharger?.id || "--" },
        { title: "Version", value: "v" + getters.getCurrentCharger?.ver || "--" },
        {
          title: "Registriert",
          value: getters.getCurrentCharger?.meta[ "register_date" ]
            ? new Date(
              getters.getCurrentCharger?.meta[ "register_date" ]
            ).toLocaleDateString()
            : "N/A",
        },
        {
          title: "Temperatur",
          value: `${getters.getCurrentCharger.averageTemp ?? "--"} °C`,
        },
        {
          title: "Gesamt (mA)",
          value: `${getters.getCurrentCharger.currentTotal ?? "--"} mA`,
        },
      ];
    },

    getLoading: (state) => state.loading,
    getErrorChargeMode: state => state.error[ Status.SET_MODE ],
    getErrorChargeProfile: state => state.error[ Status.SET_PROFILE ],
    isLoadingChargeMode: (state) => state.loading[ Status.SET_MODE ],
    isLoadingChargeProfile: (state) => state.loading[ Status.SET_PROFILE ],
    isLoadingSaveProfile: (state) => state.loading[ Status.SAVE_PROFILE ],
    isLoadingSaveProfileGroup: (state) => state.loading[ Status.SAVE_PROFILE_GROUP ],
    isErrorChargeMode: (state) => state.error[ Status.SET_MODE ],
    isErrorChargeProfile: (state) => state.error[ Status.SET_PROFILE ],
    isErrorSaveProfile: (state) => state.error[ Status.SAVE_PROFILE ],
    isErrorSaveProfileGroup: (state) => state.error[ Status.SAVE_PROFILE_GROUP ],
  },
};
