<template>
  <v-menu
    content-class="rounded-lg"
    v-model="showChargerHistory"
    :close-on-content-click="false"
    v-click-outside="(this.showChargerHistory = false)"
    :nudge-bottom="64"
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon>
        <v-icon>mdi-web-clock</v-icon>
      </v-btn>
    </template>
    <v-card width="max-content">
      <v-list width="520">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-web-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Zuletzt angesehen </v-list-item-title>
            <v-list-item-subtitle>
              Historie der zuletzt besuchten Charger- bzw. Batterie-Seiten
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-chip-group v-model="tabIndex" color="accent" style="margin: 0 1rem">
          <v-chip outlined>Charger</v-chip>
          <v-chip outlined>Batterien</v-chip>
        </v-chip-group>

        <v-divider></v-divider>

        <div v-if="tabIndex == 0">
          <v-list-item v-if="!getChargerHistory?.length">
            <v-list-item-content>
              <v-list-item-title style="padding: 1rem 0">
                <i style="opacity: 0.5">Keine Einträge im Verlauf</i>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="(charger, i) of getChargerHistory"
            :key="charger.id + i"
            link
            @click="$router.push('/charger/' + charger.id)"
          >
            <v-list-item-icon>
              <v-icon>$battery</v-icon>
            </v-list-item-icon>
            <v-list-item-content
              @click="$router.push('/charger/' + charger.id)"
            >
              <v-list-item-title>
                {{ charger?.name || charger.id }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="charger.name">
                <!-- Zuletzt vor {{ lastViewed(charger) }} -->
                {{ charger.id }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click.stop="removeCharger(charger.id)">
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
        <div v-else>
          <v-list-item v-if="!getBatteryHistory?.length">
            <v-list-item-content>
              <v-list-item-title style="padding: 1rem 0">
                <i style="opacity: 0.5">Keine Einträge im Verlauf</i>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="(battery, i) of getBatteryHistory"
            :key="battery.id + i"
            link
            @click="$router.push('/battery/' + battery.id)"
          >
            <v-list-item-icon>
              <v-icon>$charger</v-icon>
            </v-list-item-icon>
            <v-list-item-content
              @click="$router.push('/battery/' + battery.id)"
            >
              <v-list-item-title>
                {{ battery.id }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Zuletzt vor
                {{ lastViewed(battery.lastSeen || battery.data.ts) }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click.stop="removeBattery(battery.id)">
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    showChargerHistory: true,
    deviceHistory: [],
    tabIndex: 0,
  }),

  computed: {
    ...mapGetters("charger", ["getChargerByID", "getChargerHistory"]),
    ...mapGetters("battery", ["getBatteryByID", "getBatteryHistory"]),
  },

  mounted() {
    try {
      const lastVisited = localStorage.getItem("deviceHistory");
      if (!lastVisited) localStorage.setItem("deviceHistory", "[]");
      else this.deviceHistory = [...JSON.parse(lastVisited)].reverse();
    } catch (err) {
      err;
    }
  },
  methods: {
    ...mapActions("charger", ["removeFromChargerHistory"]),
    ...mapActions("battery", ["removeFromBatteryHistory"]),
    lastViewed(timestamp) {
      const delta = Math.abs(Date.now() - timestamp) / 1000;

      //return the amount of days
      if (delta > 86400) {
        const days = Math.floor(delta / 86400);
        return new String(days + " Tag") + (days > 1 ? "en" : "");
      }

      //return the amount of hours if more than 60min ago
      if (delta > 3600) {
        const hours = Math.floor(delta / 3600) % 24;
        return new String(hours + " Stunde") + (hours > 1 ? "n" : "");
      }

      //return the amount of minutes
      if (delta > 59) {
        const minutes = Math.floor(delta / 60) % 60;
        return new String(minutes + " Minute") + (minutes > 1 ? "n" : "");
      }

      const seconds = Math.floor(delta);
      return new String(seconds + " Sekunde") + (seconds > 1 ? "n" : "");
    },
    removeCharger(id) {
      this.removeFromChargerHistory(id);
    },
    removeBattery(id) {
      this.removeFromBatteryHistory(id);
    },
  },
  watch: {
    "$store.state.deviceHistory"(value) {
      this.deviceHistory = [...JSON.parse(JSON.stringify(value))].reverse();
    },
  },
};
</script>
