import ChargeProfile from "../../db/classes/chargeProfile";
import ChargeMode from "../../db/classes/chargeMode";
import dbHelper from "../../db/DBHelper";
import miConfig, { Logging } from "../../miConfig";

// Debug
const logIt = Logging.ChargeModes.STORE;

export default {
  namespaced: true,
  name: "chargemode",
  state: {
    chargeModes: [],
    chargeProfiles: [],
    chargeProfileGroups: [],
  },

  mutations: {
    // Modes
    SET_CHARGE_MODES(state, modes) {
      state.chargeModes = modes.map(mode => mode instanceof ChargeMode ? mode : new ChargeMode(mode));
      if (logIt) console.log("SET_CHARGE_MODES", state.chargeModes);
    },

    SET_CHARGE_MODE(state, mode) {
      const index = state.chargeModes.findIndex(m => m.id === mode.id);
      if (index !== -1) {
        state.chargeModes[ index ] = mode instanceof ChargeMode ? mode : new ChargeMode(mode);
        if (logIt) console.log("SET_CHARGE_MODE", state.chargeModes[ index ]);
      }
    },

    UPDATE_CHARGE_MODE(state, changes) {
      if (!changes.id) return console.warn("Cannot update w/o Id");
      const index = state.chargeModes.findIndex(m => m.id === changes.id);
      if (index !== -1) {
        const mode = state.chargeModes[ index ];
        Object.assign(mode, changes)
        state.chargeModes[ index ] = mode
        if (logIt) console.log("UPDATE_CHARGE_MODE", state.chargeModes[ index ]);
      }
    },

    UPDATE_CHARGE_MODES(state, changesArray) {
      if (!changesArray.length) return console.warn("No changes to be saved");
      let updatedChargeModes = [ ...state.chargeModes ];

      changesArray.forEach(changes => {
        if (!changes.id) return;
        const index = updatedChargeModes.findIndex(m => m.id === changes.id);
        if (index !== -1) {
          const mode = updatedChargeModes[ index ];
          Object.assign(mode, changes);
          updatedChargeModes[ index ] = mode;
        }
      });

      state.chargeModes = updatedChargeModes;
    },

    ADD_CHARGE_MODE(state, mode) {
      if (!(mode instanceof ChargeMode)) {
        mode = new ChargeMode(mode);
      }
      state.chargeModes.push(mode);
      if (logIt) console.log("ADD_CHARGE_MODE", mode);
    },

    REMOVE_CHARGEMODE(state, id) {
      state.chargeModes = state.chargeModes.filter(mode => mode.id !== id);
      if (logIt) console.log("REMOVE_CHARGEMODE", id);
    },

    // Profiles
    SET_CHARGE_PROFILES(state, profiles) {
      state.chargeProfiles = profiles.map(profile => profile instanceof ChargeProfile ? profile : new ChargeProfile(profile));
      if (logIt) console.log("SET_CHARGE_PROFILES", state.chargeProfiles);
    },

    SET_CHARGE_PROFILE(state, profile) {
      const index = state.chargeProfiles.findIndex(p => p.id === profile.id);
      if (index !== -1) {
        state.chargeProfiles[ index ] = profile instanceof ChargeProfile ? profile : new ChargeProfile(profile);
        if (logIt) console.log("SET_CHARGE_PROFILE", state.chargeProfiles[ index ]);
      }
    },

    ADD_CHARGE_PROFILE(state, profile) {
      if (!(profile instanceof ChargeProfile)) {
        profile = new ChargeProfile(profile);
      }
      state.chargeProfiles.push(profile);
      if (logIt) console.log("ADD_CHARGE_PROFILE", profile);
    },

    REMOVE_CHARGE_PROFILE(state, id) {
      state.chargeProfiles = state.chargeProfiles.filter(profile => profile.id !== id);
      if (logIt) console.log("REMOVE_CHARGE_PROFILE", id);
    },

    // Profile Groups
    SET_CHARGE_PROFILE_GROUPS(state, groups) {
      state.chargeProfileGroups = groups;
      if (logIt) console.log("SET_CHARGE_PROFILE_GROUPS", state.chargeProfileGroups);
    },

    SET_CHARGE_PROFILE_GROUP(state, group) {
      const index = state.chargeProfileGroups.findIndex(g => g.id === group.id);
      if (index !== -1) {
        state.chargeProfileGroups[ index ] = group;  // Assuming groups are plain objects and don't need special handling
        if (logIt) console.log("SET_CHARGE_PROFILE_GROUP", state.chargeProfileGroups[ index ]);
      }
    },

    ADD_CHARGE_PROFILE_GROUP(state, group) {
      state.chargeProfileGroups.push(group);
      if (logIt) console.log("ADD_CHARGE_PROFILE_GROUP", group);
    },

    REMOVE_CHARGE_PROFILE_GROUP(state, id) {
      state.chargeProfileGroups = state.chargeProfileGroups.filter(group => group.id !== id);
      if (logIt) console.log("REMOVE_CHARGE_PROFILE_GROUP", id);
    },
  },

  actions: {
    // Modes
    async fetchChargeModes({ commit }) {
      try {
        const chargeModes = await dbHelper.ChargeTypes.getChargeModes()
        // Dev
        if (!chargeModes.length) {
          chargeModes.push(...Object.values(miConfig.chargemodes))
        }
        commit("SET_CHARGE_MODES", chargeModes);
      } catch (error) {
        console.error(error);
      }
    },

    async addChargeMode({ commit, state }, chargeMode) {
      chargeMode.id = Date.now();
      commit("ADD_CHARGE_MODE", chargeMode);
      localStorage.setItem(
        "chargeModes",
        JSON.stringify(Object.values(state.chargeModes))
      );
      return chargeMode;
    },

    async removeChargeMode({ commit, state }, id) {
      commit("REMOVE_CHARGE_MODE", id);
      localStorage.setItem(
        "chargeModes",
        JSON.stringify(Object.values(state.chargeModes))
      );
    },

    saveChargeModes({ commit }, modeChanges) {
      commit("UPDATE_CHARGE_MODES", modeChanges)
      // Bulk?
      modeChanges.forEach(async changes => {
        await dbHelper.ChargeTypes.updateChargeMode(modeChanges.id, changes)
      })
    },

    // Profiles
    async fetchChargeProfiles({ commit }) {
      let profiles = [];
      try {
        commit
        // profiles = await dbHelper.ChargeTypes.getChargerProfiles()
        // if (logIt) console.log("Profiles", profiles);
      } catch (error) {
        console.error(error);
      }
      const local = JSON.parse(localStorage.getItem("profiles")) || []
      profiles.push(...local)
    },

    async addChargeProfile({ state, commit }, chargeProfile) {
      try {
        // profile = await dbHelper.ChargeTypes.addChargeProfiles(chargeProfile);
        // commit("ADD_CHARGE_PROFILE", profile);
        // return profile;
      } catch (error) {
        console.error(error);
      }
      chargeProfile.id = Date.now()
      commit("ADD_CHARGE_PROFILE", chargeProfile);
      localStorage.setItem("profiles", JSON.stringify(state.chargeProfiles))
      return chargeProfile
    },

    async removeChargeProfile({ commit, state }, id) {
      commit("REMOVE_CHARGE_PROFILE", id);
      localStorage.setItem(
        "chargeProfiles",
        JSON.stringify(Object.values(state.chargeProfiles))
      );
    },

    // Profile Groups
    async fetchChargeProfileGroups({ commit }) {
      try {
        let chargeProfileGroups = JSON.parse(localStorage.getItem("chargeProfileGroups"));
        if (chargeProfileGroups) {
          commit("SET_CHARGE_PROFILE_GROUPS", chargeProfileGroups);
        }
      } catch (error) {
        console.error(error);
      }
    },

    async setChargeProfileGroup({ commit, state }, chargeProfileGroup) {
      const index = state.chargeProfileGroups.findIndex(g => g.id === chargeProfileGroup.id);
      if (index !== -1) {
        commit("SET_CHARGE_PROFILE_GROUP", chargeProfileGroup);
      } else {
        chargeProfileGroup.id = Date.now();
        commit("ADD_CHARGE_PROFILE_GROUP", chargeProfileGroup);
      }
      localStorage.setItem("chargeProfileGroups", JSON.stringify(state.chargeProfileGroups));
    },

    async addChargeProfileGroup({ commit, state }, chargeProfileGroup) {
      chargeProfileGroup.id = Date.now();
      commit("ADD_CHARGE_PROFILE_GROUP", chargeProfileGroup);
      localStorage.setItem("chargeProfileGroups", JSON.stringify(state.chargeProfileGroups));
      return chargeProfileGroup;
    },

    async removeChargeProfileGroup({ commit, state }, id) {
      commit("REMOVE_CHARGE_PROFILE_GROUP", id);
      localStorage.setItem("chargeProfileGroups", JSON.stringify(state.chargeProfileGroups));
    }
  },

  getters: {
    // Modes
    getChargeModes: (state) => state.chargeModes,
    getChargeModeByID: (state) => (id) => state.chargeModes.find(mode => mode.id === id),

    // Profiles
    getChargeProfiles: (state) => state.chargeProfiles,
    getChargeProfileByID: (state) => (id) => state.chargeProfiles.find(profile => profile.id === id),
    hasProfiles: (_, getters) => getters.getChargeProfiles?.length > 0 || false,

    // Profile Groups
    getChargeProfileGroups: (state) => state.chargeProfileGroups,
    getChargeProfileGroupByID: (state) => (id) => state.chargeProfileGroups.find(group => group.id === id),

    // Existing getter
    getProfileBySlots: (state) => (slots) => {
      const foundProfile = state.chargeProfiles.find(profile => JSON.stringify(profile.slots) === JSON.stringify(slots));
      return foundProfile || null;
    },

    getChargeList: (_, getters) => {
      let modes = [
        { header: "Modes" },
        ...getters.getChargeModes.map(mode => ({
          text: mode.text,
          value: `mode-${mode.id}`
        }))
      ];

      let profiles = [
        { header: "Profiles" },
        ...getters.getChargeProfiles.map(profile => ({
          text: profile.name,
          value: `profile-${profile.id}`
        }))
      ];

      return [ ...modes, ...profiles ];
    }
  },


};
