import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
// import webSocketHelper from "./db/WebSocketHelper";
// import VueNativeSock from "vue-native-websocket";
// import { WS_ADDRESS } from "./miConfig";

// Test before push
if (process.env.NODE_ENV === "production") {
  console.log = console.warn = () => { };
}

Vue.config.productionTip = false;

// Vue.use(VueNativeSock, WS_ADDRESS + "/stream", {
//   format: "json", // or 'text' if your server expects text
//   reconnection: true,
//   reconnectionAttempts: 5,
//   reconnectionDelay: 3000,
// });

const app = new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

app
// webSocketHelper.initWebSocket(app);
