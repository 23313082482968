<template>
  <base-dialog
    dialKey="profileGroupConfig"
    title="Profile Group Editor"
    subtitle="Configure Profile Group Settings"
    width="40vw"
    icon="mdi-calendar-clock"
    @save="onSave()"
    :disableSave="hasErrors"
  >
    <template #topactions>
      <hover-button
        v-show="originalProfileGroup"
        title="Reset"
        icon="mdi-restore"
        :openDelay="1000"
        small
        backgroundColor="#fefefe10"
        @click="restoreGroupSetting"
      />
    </template>

    <div class="vertical gapped" v-if="profileGroup">
      <v-text-field
        v-model="profileGroup.name"
        label="Profile Group Name"
        outlined
        color="onBack"
        rounded
        hide-details
      ></v-text-field>

      <div
        class="vertical gapped"
        style="background-color: #fefefe10; border-radius: 1rem; padding: 8px"
      >
        <h4 style="margin: 0 0 -0.5rem 0.5rem">Was? Wann?</h4>
        <v-select
          :items="getChargeList"
          label="Charge-Typ"
          v-model="selectedChargeType"
          @change="handleChargeTypeChange"
          item-text="text"
          item-value="value"
          rounded
          outlined
          hide-details
          color="onBack"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content v-if="item.header">
              <v-list-item-title class="font-weight-bold">
                {{ item.header }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
        <!-- Days Configuration -->
        <v-select
          v-model="profileGroup.timeframe.days"
          :items="daysOfWeek"
          label="Days"
          prepend-inner-icon="mdi-calendar-range"
          item-color="onBack"
          multiple
          rounded
          outlined
          color="onBack"
          hide-details
        ></v-select>

        <div class="horizontal gapped">
          <!-- Start Time Configuration -->
          <v-menu
            ref="startMenu"
            v-model="startMenu"
            :close-on-content-click="false"
            :nudge-right="40"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="profileGroup.timeframe.start"
                label="Start Time"
                append-icon="mdi-clock-start"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                color="onBack"
                rounded
                :error-messages="timeframeErrors.start"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="startMenu"
              color="#fefefe10"
              format="24hr"
              v-model="profileGroup.timeframe.start"
              @input="startMenu = false"
            ></v-time-picker>
          </v-menu>

          <!-- End Time Configuration -->
          <v-menu
            ref="endMenu"
            v-model="endMenu"
            :close-on-content-click="false"
            :nudge-right="40"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="profileGroup.timeframe.end"
                label="End Time"
                append-icon="mdi-clock-end"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                color="onBack"
                rounded
                :error-messages="timeframeErrors.end"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="endMenu"
              v-model="profileGroup.timeframe.end"
              format="24hr"
              color="#fefefe10"
              @input="endMenu = false"
            ></v-time-picker>
          </v-menu>
        </div>
      </div>
    </div>
  </base-dialog>
</template>

<script>
import BaseDialog from "./BaseDialog.vue";
import { mapActions, mapGetters } from "vuex";
import HoverButton from "../components/buttons/HoverButton.vue";
import ChargeProfileGroup from "../../db/classes/chargeProfileGroup";
import { dialogs } from "../../store/modules/dialogModule";

export default {
  components: {
    BaseDialog,
    HoverButton,
  },
  data() {
    return {
      profileGroup: null,
      originalProfileGroup: null,
      selectedChargeType: null,
      daysOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      startMenu: false,
      endMenu: false,
    };
  },

  mounted() {
    const data = this.getDialogData(dialogs.profileGroupConfig);
    this.profileGroup = new ChargeProfileGroup(data);
    if (data) {
      this.originalProfileGroup = new ChargeProfileGroup(data);
    }
  },

  computed: {
    ...mapGetters("chargemode", [
      "getChargeList",
      "getChargeModes",
      "getChargeProfiles",
    ]),
    ...mapGetters("dialog", ["getDialogData"]),

    timeframeErrors() {
      const errors = {};
      if (
        new Date(`1970-01-01 ${this.profileGroup.timeframe.start}`) >
        new Date(`1970-01-01 ${this.profileGroup.timeframe.end}`)
      ) {
        errors.start = "Die Startzeit sollte vor der Endzeit liegen.";
        errors.end = "Die Endzeit sollte nach der Startzeit liegen.";
      }
      return errors;
    },

    hasErrors() {
      if (this.profileGroup) {
        const { start, end } = this.profileGroup.timeframe;
        return new Date(`1970-01-01 ${start}`) > new Date(`1970-01-01 ${end}`);
      }
      return false;
    },
  },

  methods: {
    ...mapActions("chargemode", ["setChargeProfileGroup"]),
    async onSave() {
      if (!this.hasErrors) {
        await this.setChargeProfileGroup(this.profileGroup);
      } else {
        console.error("Cannot save due to errors!");
      }
    },

    handleChargeTypeChange(selected) {
      const [type, id] = selected.split("-");

      if (type === "mode") {
        // Handle mode selection
        const selectedMode = this.getChargeModes.find(
          (mode) => mode.id === Number(id)
        );
        console.log("Selected Mode:", selectedMode);
      } else if (type === "profile") {
        // Handle profile selection
        const selectedProfile = this.getChargeProfiles.find(
          (profile) => profile.id === Number(id)
        );
        console.log("Selected Profile:", selectedProfile);
      }
    },

    restoreGroupSetting() {
      this.profileGroup = Object.assign({}, this.originalProfileGroup);
    },
  },
};
</script>
