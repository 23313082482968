import axios from "axios";
import { SERVER_ADDRESS, USER_TOKEN } from "../../miConfig";

// * * * * * * * * * * * * * * * * * * * *
// *               GENERAL               *
// * * * * * * * * * * * * * * * * * * * *

function addUserToken(params) {
  if (USER_TOKEN) {
    params.headers = {
      authorization: "Bearer " + USER_TOKEN
    }
  }

  return params;
}

// * * * * * * * * * * * * * * * * * * * *
// *         GET & POST Methoden         *
// * * * * * * * * * * * * * * * * * * * *

/**
 * Führt eine HTTP GET-Anforderung an den angegebenen Pfad aus und gibt die empfangenen Daten zurück, sofern vorhanden.
 * @param {string} path - Der Pfad, an den die GET-Anforderung gesendet wird.
 * @param {Object} params - Ein optionales Konfigurationsobjekt für die GET-Anforderung.
 * @returns {Promise<Object|null>} Gibt die empfangenen Daten als JavaScript-Objekt zurück, wenn die Anforderung erfolgreich war, andernfalls `null`.
 */
export async function get(path, params = {}) {
  addUserToken(params);

  try {
    let response = await axios.get(SERVER_ADDRESS + path, params);
    return response.data;
  } catch (error) {
    return null;
  }

}

/**
 * Führt eine synchronisierte HTTP GET-Anforderung an den angegebenen Pfad aus und ruft eine Funktion auf, um die empfangenen Daten zu verarbeiten.
 * @param {string} path - Der Pfad, an den die GET-Anforderung gesendet wird.
 * @param {Object} params - Ein optionales Konfigurationsobjekt für die GET-Anforderung.
 * @param {Function} exec - Eine Funktion, die aufgerufen wird, wenn die Anforderung erfolgreich ist und die empfangenen Daten als Argument erhält.
 */
export async function getSync(path, params = {}, exec) {
  addUserToken(params);
  axios.get(SERVER_ADDRESS + path, params).then(exec);
}

/**
 * Führt eine HTTP POST-Anforderung an den angegebenen Pfad aus und gibt die empfangenen Daten zurück, sofern vorhanden.
 * @param {string} path - Der Pfad, an den die POST-Anforderung gesendet wird.
 * @param {Object} body - Die Daten, die im Anforderungskörper gesendet werden.
 * @param {Object} params - Ein optionales Konfigurationsobjekt für die POST-Anforderung.
 * @returns {Promise<Object|null>} Gibt die empfangenen Daten als JavaScript-Objekt zurück, wenn die Anforderung erfolgreich war, andernfalls `null`.
 */
export async function post(path, body, params = {}) {
  addUserToken(params);

  let response = await axios.post(SERVER_ADDRESS + path, body, params);
  return response.data;
}

/**
 * Führt eine synchronisierte HTTP POST-Anforderung an den angegebenen Pfad aus, sendet einen Anforderungskörper und ruft eine Funktion auf, um die empfangenen Daten zu verarbeiten.
 * @param {string} path - Der Pfad, an den die POST-Anforderung gesendet wird.
 * @param {Object} body - Die Daten, die im Anforderungskörper gesendet werden.
 * @param {Object} params - Ein optionales Konfigurationsobjekt für die POST-Anforderung.
 * @param {Function} exec - Eine Funktion, die aufgerufen wird, wenn die Anforderung erfolgreich ist und die empfangenen Daten als Argument erhält.
 */
export async function postSync(path, body, params = {}, exec) {
  addUserToken(params);
  axios.post(SERVER_ADDRESS + path, body, params).then(exec);
}
