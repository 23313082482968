import { GetPaths, Logging } from '../../miConfig';
import webSocketHelper, { SUBSCRIBE_BATTERY } from '../WebSocketHelper';
import * as Base from './BaseModule';

const logIt = Logging.db || Logging.battery.db

export class BatteryModule {

  // * * * * * * * * * * * * * * * * * * * *
  // *               GETTERS               *
  // * * * * * * * * * * * * * * * * * * * *

  async getBatteryById(id) {
    if (id == '-1' || id == undefined) return;
    return await Base.get(GetPaths.BATTERY + id);
  }

  async getBatteries(locId = -1, bldgId = -1, rackId = -1, chrgId = -1) {
    const queryString = `?location=${locId}&building=${bldgId}&rack=${rackId}&charger=${chrgId}`;
    return await Base.get(GetPaths.ALL_BATTERIES + queryString);
  }

  async getBatteryCycles(id) {
    if (id == "-1" || id == "undefined") return [];
    return await Base.get(GetPaths.BTRY_CYCLES.replace(":id", id));
  }

  async getBatteryHistory(batteryID) {
    if (batteryID == null)
      return console.warn("Can't get Battery History w/o ID");

    const queryString = `/battery/history/${batteryID}`;
    const response = await Base.get(queryString);
    return response;
  }

  async getSlotHistory({ chargerID, slotIndex }) {
    if (chargerID == null || slotIndex == null)
      return console.warn("Can't get Slot History w/o IDs");

    const queryString = `/history/${chargerID}/${slotIndex}`;
    const response = await Base.get(queryString);
    return response.result;
  }


  listenTo(batteryId, onFound, onError, extras) {
    try {
      if (logIt) console.log("Listen to", batteryId);
      const unsubFun = webSocketHelper
        .addListener(
          SUBSCRIBE_BATTERY,
          batteryId,
          onFound,
          extras
        );
      return unsubFun;
    } catch (error) {
      onError(error);
    }
  }

}
