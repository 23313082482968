<template>
  <div class="d-flex flex-row align-center">
    <v-autocomplete
      v-if="!isMobile"
      clearable
      class="mr-0"
      append-icon=""
      style="
        width: 400px;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      "
      prepend-inner-icon="mdi-magnify"
      @change="($event) => redirectTo($event)"
      :loading="isLoading"
      :items="devicesFound"
      item-text="name"
      :search-input.sync="searchInput"
      placeholder="Suche MiCharge, Akkus..."
      :no-data-text="noDataText"
      solo-inverted
      return-object
      hide-details
      dense
      flat
    >
      <template slot="item" slot-scope="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs">
          <v-list-item-icon>
            <v-icon>
              {{ item.type == "BTY" ? "mdi-battery-80" : "mdi-power-plug" }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            {{ item.name }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <v-combobox
      v-model="selectedFilter"
      transition="slide-y-transition"
      label="Filter"
      :items="filterItems"
      class="ml-0"
      ref="filterselect"
      style="
        width: 120px;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      "
      solo-inverted
      dense
      hide-details
      flat
    >
      <template v-slot:item="{ item }">
        <v-list-item @click="selectItem(item)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import dbHelper from "../../../../db/DBHelper";

export default {
  data: () => ({
    searchInput: "",
    isLoading: false,
    timeout: undefined,
    devicesFound: [],

    selectedFilter: undefined,
    filterItems: [
      { text: "Charger", icon: "mdi-library-shelves", id: "mcg" },
      { text: "Akku", icon: "mdi-battery-80", id: "bty" },
    ],
  }),

  watch: {
    searchInput(query) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchDevices(query);
      }, 400);
    },
  },

  computed: {
    noDataText() {
      return this.searchInput == null || this.searchInput == ""
        ? "Text eingeben, um Suche zu beginnen..."
        : "Keine Einträge gefunden...";
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  methods: {
    selectItem(item) {
      this.selectedFilter = item;
      this.$refs.filterselect.blur();
    },
    redirectTo(item) {
      if (!item || item == null) return;

      this.searchInput = "";
      this.devicesFound = [];
      if (item.type == "BTY") {
        this.$router.push(`/battery/${item.name}`);
      } else {
        this.$router.push(`/charger/${item.name}`);
      }
    },
    searchDevices(query) {
      if (
        query == "" ||
        query == null ||
        query == undefined ||
        query == "null" ||
        query == "undefined"
      )
        return;
      this.isLoading = true;

      let searchQuery = encodeURI("?query=" + query);
      if (this.selectedFilter) searchQuery += "&type=" + this.selectedFilter.id;
      this.fetchDevices(searchQuery);
    },
    async fetchDevices(params = "") {
      this.devicesFound = await dbHelper.searchDevices(params);
      this.isLoading = false;
    },
  },
};
</script>
