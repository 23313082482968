// TODO: Struct überarbeiten
import { cloneDeep } from "lodash";
import Struct from "./data/rack.json";

const rackSlots = [];
for (let i = 1; i < 11; i++) {
  rackSlots.push({
    position: i,
    chargerID: null,
  });
}

export default class Rack {
  constructor(rack) {
    if (!rack.id && rack.rackId) {
      this.id = rack.rackId
      delete rack.rackId
      console.warn("Rack _id_ is _rackId_");
    }
    Object.assign(this, Struct);
    this.slots = cloneDeep(rackSlots);
    if (rack) {
      Object.assign(this, rack);
      if (rack instanceof Rack) return;
    }
  }
}
