// listeners = {};
// errListeners = [];
// newListeners = {
//   "charger": [],
//   "battery": [],
// };
// const listeners = {
//   "__": [ callback1, callback2 ],
//   "chargerId2": [ { callback, [fields] }, callback2 ],
//   "chargerId3": [ callback1, callback2 ],
//   "chargerId4": [ callback1, callback2 ],
//   "batteryId1": [ callback1, callback2 ],
//   "batteryId2": [ callback1, callback2 ],
//   "batteryId3": [ callback1, callback2 ],
//   "batteryId4": [ callback1, callback2 ],
// }

// REQID : {temp}
// return temp
//  {temp}, {ttf, curr}
// { temp , ttf, curr }
//
// message = {
//   items: {
//      chargers : {
//        "MCG123": { ...req }
//        }
//     "MCG123123": {temp : 23}
//   },
//   newItems: {
//     batteries: [ { id: "" }, { id: "" } ],
//   },
//   errors : []
// }

import { Logging } from "../miConfig";
const logIt = Logging.ws;

//prettier-ignore
export const SUBSCRIBE_CHARGER = "listenCharger"
export const SUBSCRIBE_SLOT = "listenSlot"
export const SUBSCRIBE_BATTERY = "listenBattery"
export const SUBSCRIBE_ERROR = "listenError"
export const SUBSCRIBE_NEW = "listenNew"
const UNSUBSCRIBE = "unsubscribe"

class WebSocketHelper {

  // keys : itemIds, 
  // values: listeners,
  listeners = {};
  newListeners = {
    charger: [],
    battery: [],
  }
  errListeners = [];

  /**
   * Extrahiert die erstellte Websocket-Verbindung aus dem Vue Objekt.
   * Konfiguriert auch den Standard-Listener für eingehende Nachrichten.
   */
  initWebSocket(vueInstance) {
    this.vueInstance = vueInstance;
    this.webSocket = vueInstance.$socket;
    this.webSocket.onmessage = (message) => {
      if (logIt) console.log("Message received", message);
      const data = JSON.parse(message.data)
      if (logIt) console.log("Data parsed form Message", data);
      this.#handleMessage(data);
    };
  }

  /**
   * Fügt einen Listener für eingehende Nachrichten hinzu und gibt eine eindeutige ID zurück.
   * @param {function} callback - Die Callback-Funktion, die aufgerufen wird, wenn eine Nachricht eintrifft.
   * @returns {string} - Die eindeutige ID des hinzugefügten Listeners.
   */
  addListener(type, id, callback, extras) {
    if (logIt) console.log("Adding Listener", type, id, callback);

    let idListeners = this.listeners[ id ];

    if (idListeners?.includes(callback)) {
      console.warn("Listener already exists", this.listeners);
      return;
    }
    else if (!idListeners) {
      this.listeners[ id ] = [ callback ]
    }
    else {
      this.listeners[ id ].push(callback);
    }

    let reqId = this.#sendMessage(type, id, extras)

    return () => {
      this.unsubscribe(reqId)
    }
  }

  addNewChargerListener(callback) {
    const newListeners = this.newListeners.charger;
    if (newListeners.includes(callback)) {
      console.warn("Listener already exists", this.listeners);
      return;
    }
    newListeners.push(callback);
  }

  addNewBatteryListener(callback) {
    const newListeners = this.newListeners.battery;
    if (newListeners.includes(callback)) {
      console.warn("Listener already exists");
      return
    }
    newListeners.push(callback);
  }

  addErrorListener(callback) {
    if (this.errorListeners.includes(callback)) {
      console.warn("Listener already exists");
      return
    }
    this.errListeners.push(callback)
  }

  /**
   * Entfernt einen Listener anhand seiner ID.
   * @param {string} listenerId - Die ID des zu entfernenden Listeners.
   */
  removeListener(id, listener) {
    const idListeners = this.listeners[ id ]
    if (!idListeners) {
      console.warn("Listener does not exists");
      return;
    }

    let index = idListeners.indexOf(listener)
    idListeners.splice(index, 1);

    if (idListeners.length == 0) {
      this.unsubscribe(id)
      delete this.listeners[ id ]
    }
  }

  /**
   * Entfernt alle Listener für eingehende Nachrichten.
   */
  removeAllListeners() {
    Object.keys(this.listeners).forEach(it => {
      this.unsubscribe(it)
    })
    this.listeners = {};

    // New
    Object.values(this.newListeners).forEach(array => {
      array.forEach(listener => {
        this.unsubscribe(listener)
      })
    })
    this.newListeners = {
      charger: [],
      battery: []
    };

    // Error
    Object.values(this.errorListeners).forEach(array => {
      array.forEach(listener => {
        this.unsubscribe(listener)
      })
    })
    this.errorListeners = [];
  }

  /**
   * Private Methode zur Verarbeitung von eingehenden Nachrichten.
   * Ruft alle Listener auf und übergibt die Nachricht an diese.
   * @param {Object} message - Die empfangene Nachricht.
   */
  #handleMessage(message) {
    if (logIt) console.log("HANDLE MESSAGE", message);
    const changedItems = message?.items
    const newItems = message?.newItems
    const errors = message?.errors

    // Items changed
    if (changedItems) {
      if (logIt) console.log("Changed Items:", changedItems);
      Object.entries(changedItems).forEach(([ type, map ]) => {
        if (logIt) console.log("Changed EntriesMap", type, map);
        Object.entries(map).forEach(([ id, item ]) => {
          this.listeners[ id ]?.forEach(
            callback => {
              if (logIt) {
                if (logIt) console.log("Callback", id, item);
                console.table(item.slots)
              }
              callback(item)
            })
        })
      })
    }

    // New Charger & Battery
    if (newItems) {
      const newListeners = this.newListeners
      const { chargers, batteries } = newItems;

      // Chargers
      newListeners.charger?.forEach(callback => {
        callback(chargers)
      })

      // Batteries
      newListeners.battery?.forEach(callback => {
        callback(batteries)
      })
    }

    // Errors
    if (errors) {
      this.errorListeners.forEach(it => {
        it(errors)
      })
    }
  }

  /**
   * Interne Methode zur Generierung einer eindeutigen Listener-ID.
   * @returns {string} - Eine eindeutige ID.
   */
  #generateId() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  /**
   * Sendet eine Websocket-Nachricht an das Backend.
   * @param {Object} packet Ein Datenpaket mit den Feldern `target, mode`
   */
  #sendMessage(mode, id, extras) {
    const packet = {
      requestId: this.#generateId(),
      mode: mode,
      target: id,
      scope: extras
    }

    if (logIt) console.log("Sending Packet", packet);
    this.webSocket.send(JSON.stringify(packet));
    if (logIt) console.log("Packet sent", packet);

    return packet.requestId;
  }

  /**
   * Setzt den Websocket in einen Idle-State, um den Datenverkehr zu pausieren.
   */
  unsubscribe(listenerId) {
    const packet = {
      requestId: listenerId,
      mode: UNSUBSCRIBE
    };
    if (logIt) console.log("Unsubscribe", packet, listenerId);
    this.webSocket.send(JSON.stringify(packet));
  }
}

const webSocketHelper = new WebSocketHelper();
export default webSocketHelper;
