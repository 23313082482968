import * as Base from './dbModules/BaseModule';
import { ChargerModule } from "./dbModules/ChargerModule";
import { BatteryModule } from "./dbModules/BatteryModule";
import { GetPaths, Logging, PostPaths } from "../miConfig";
import webSocketHelper, { SUBSCRIBE_SLOT } from './WebSocketHelper';

const logIt = Logging.db

const usersDemo = [
  {
    key: "DGE",
    name: "Dennis Grosse",
    role: 0,
    icon: "mdi-chess-king",
    lastSeen: new Date().getTime(),
  },
  {
    key: "FHT",
    name: "Franko Heckert",
    role: 0,
    icon: "mdi-code-array",
    lastSeen: new Date().getTime(),
  },
  {
    key: "DGF",
    name: "Dirk Graf",
    role: 0,
    icon: "mdi-castle",
    lastSeen: new Date().getTime(),
  },
  {
    key: "GST",
    name: "Gast",
    role: 3,
    icon: "mdi-account",
    lastSeen: new Date(Date.now() - 1231230),
  },
]

class DBHelper {

  Charger = new ChargerModule();
  Battery = new BatteryModule();


  async searchDevices(params) {
    return await Base.get(`${GetPaths.SEARCH}${params}`);
  }

  // In Charger oder SlotModule?
  Slot = {
    listenTo(slotId, onFound, onError, extras) {
      try {
        if (logIt) console.log("Listen to", slotId);
        const unsubFun = webSocketHelper
          .addListener(
            SUBSCRIBE_SLOT,
            slotId,
            onFound,
            extras
          );
        return unsubFun;
      } catch (error) {
        onError(error);
      }
    }
  }


  // General
  User = {
    async getUsers() {
      const users = await Base.get(GetPaths.ALL_USERS);
      return users ? users : usersDemo
    },

    async getUserData(userId) {
      if (logIt) console.log("TODO: getUserData", userId);
    },

    async updateUserData(userData) {
      if (logIt) console.log("TODO: updateUser", userData);
    },

    async getAbout() {
      return await Base.get(GetPaths.ABOUT);
    },

    async getAdminInfo() {
      return await Base.get(GetPaths.STATS);
    }
  }

  // ChargeMode & Profile
  // Adds () => return item with new id from DB!
  ChargeTypes = {
    // Mode
    async addChargeModes(chargeMode) {
      if (logIt) console.log("Todo: addChargeModes", chargeMode);
      return chargeMode
    },

    async getChargeModes() {
      if (logIt) console.log("Getting Chargemodes");
      const modes = await Base.get(GetPaths.ALL_CHARGEMODES);
      if (logIt) console.log("Got Chargemodes", modes);
      return modes
    },

    async updateChargeMode(id, chargeMode) {
      if (logIt) console.log("Todo: updateChargeMode", id, chargeMode);
    },

    async removeChargeModes(chargeModeId) {
      if (logIt) console.log("Todo: removeChargeModes", chargeModeId);
    },

    // Profile
    async addChargeProfiles(chargeProfile) {
      if (logIt) console.log("Adding profile", PostPaths.ADD_PROFILE);
      const waskommthier = await Base.post(PostPaths.ADD_PROFILE, chargeProfile)
      if (logIt) console.log("Added profile", waskommthier);
      chargeProfile.id = Date.now()
      return chargeProfile
    },

    async getChargerProfiles() {
      if (logIt) console.log("Getting Profiles");
      const profiles = await Base.get(GetPaths.ALL_MCG_PROFILES);
      if (logIt) console.log("Got Profiles");
      return profiles
    },

    async updateChargeProfile(chargeProfile) {
      if (logIt) console.log("Todo: updateChargeProfile", chargeProfile);
    },

    async removeChargerProfile(profileId) {
      if (logIt) console.log("Todo: removeChargerProfile", profileId);
    },

  }

  // Location
  Location = {
    async addLocation(location) {
      if (logIt) console.log("TODO: addLocation", location)
      location = await Base.post(PostPaths.ADD_LOCATION, location)
      return location
    },

    async getLocations() {
      return (await Base.get(GetPaths.ALL_LOCATIONS));
    },

    async getLocationByID(id) {
      return await Base.get(GetPaths.LOCATION + id);
    },

    async updateLocation(locationId, locationUpdates) {
      if (logIt) console.log("TODO: updateLocation", locationId, locationUpdates)
      return locationUpdates
    },

    async removeLocation(locationId) {
      // ???
      const res = await Base.post(PostPaths.DELETE_LOCATION, locationId)
      if (logIt) console.log("removeLocation", locationId, res)
      return res
    },
  }

  // Building
  Building = {
    async addBuilding(building) {
      console.log("Add building", building);
      return await Base.post(PostPaths.ADD_BUILDING, building)
    },

    async getBuildings() {
      return await Base.get(GetPaths.ALL_BUILDINGS);
    },

    async getBuildingByID(id) {
      return await Base.get(GetPaths.BUILDING + id)
    },

    async updateBuilding(buildingId, building) {
      console.log("TODO: updateBuilding", buildingId, building);
    },

    async removeBuilding(buildingId) {
      // ??
      const res = await Base.post(PostPaths.DELETE_BUILDING, buildingId)
      if (logIt) console.log("Removed Building", res);
      return res
    },

  }

  // Rack
  Rack = {
    async addRack(rack) {
      return await Base.post(PostPaths.ADD_RACK, rack)
    },

    async getRacks() {
      return await Base.get(GetPaths.ALL_RACKS);
    },

    async getRackById(id) {
      return await Base.get(GetPaths.RACK + id);
    },

    async updateRack(rack) {
      console.log("TODO: updateRack", rack);
      return rack
    },

    async removeRack(rackId) {
      console.log("TODO: removeRack", rackId);
    },
  }
}

const dbHelper = new DBHelper();
export default dbHelper;
