import { cloneDeep } from "lodash";
import ChargeSession from "../../../db/classes/chargeSession";
import dbHelper from "../../../db/DBHelper";
import { Logging } from "../../../miConfig";
import Vue from "vue";
import Slot from "../../../db/classes/slot";

// Debug
const logIt = Logging.slot.STORE;

const initState = {
  charger: null,
  position: null,

  currentSlot: null,

  history: null,
};

export default {
  namespaced: true,
  name: "slot",

  state: {
    ...initState,
    slots: [],

    listener: null,
    loading: {},
    error: null
  },


  mutations: {
    SET_SLOTS(state, slots) {
      state.slots = slots.map(slot =>
        slot instanceof Slot ? slot : new Slot(slot)
      );
      if (logIt) console.log("SET_SLOTS", state.slots);
    },

    SET_CURRENT_CHARGER(state, charger) {
      if (logIt) console.log('SET_CURRENT_CHARGER', charger);
      state.charger = charger;
    },

    SET_CURRENT_SLOTS(state, slots) {
      if (logIt) console.log('SET_CURRENT_SLOTS', slots);
      state.slots = slots;
    },

    SET_CURRENT_POSITION(state, position) {
      if (logIt) console.log('SET_CURRENT_POSITION', position);
      state.position = position;
    },

    SET_CURRENT_SLOT(state, miSlot) {
      if (logIt) console.log('SET_CURRENT_SLOT', miSlot);
      state.currentSlot = miSlot;
    },

    UPDATE_SLOT_DATA(state, newData) {
      if (!state.currentSlot) return;

      state.currentSlot.lastData = {
        ...state.currentSlot.lastData,
        ...newData,
      };
      if (logIt) console.log('UPDATE_SLOT_DATA', newData);
    },

    SET_HISTORY(state, session) {
      if (logIt) console.log('SET_HISTORY', session);
      state.history = session;
    },

    ADD_TO_HISTORY(state, chargeStep) {
      if (!state.history) return;
      state.history.steps.push(chargeStep);
      if (logIt) console.log('ADD_TO_HISTORY', chargeStep);
    },

    RESET_STATE(state) {
      if (logIt) console.log('RESET_STATE');
      Object.assign(state, cloneDeep(this.initState));
      state.slots = [];
    },

    SET_LOADING(state, { key, value }) {
      Vue.set(state.loading, key, value);
      state.loading[ key ] = value;
    },

    SET_ERROR(state, error) {
      state.error = error;
    }
  },

  actions: {
    async fetchHistoryData({ commit, getters, state }, miSlot) {
      if (!miSlot) {
        if (!state.currentSlot)
          return console.warn("No slot to fetch history from");
        miSlot = state.currentSlot;
      }

      let searchQuery = {
        type: "slot",
        chargerID: state.charger.id,
        slotIndex: state.position - 1,
      };


      if (logIt) console.log("Getting SlotHistory", searchQuery);
      let historyData = await dbHelper.Battery.getSlotHistory(searchQuery);
      if (!historyData?.length) {
        commit("SET_HISTORY", null);
        return console.warn("No History found for:", searchQuery);
      }
      if (logIt) {
        console.log("SlotHistory:")
        console.table(historyData.map(it => it.data));
      }

      let battery = getters[ "getCurrentBattery" ];
      let session = new ChargeSession(historyData);
      session.batteryID = battery?.id;

      commit("SET_HISTORY", session);
    },

    setSlots({ commit }, slots) {
      commit("SET_SLOTS", slots)
    },

    // Set Current-Routine
    setCurrentSlot({ state, commit, rootGetters }, { chargerId, position }) {
      if (state.listener) state.listener();
      if (!chargerId || !position) {
        console.warn("Cannot set currentSlot w/o chargerId or position ", chargerId, position);
        return
      }

      let charger = rootGetters[ "charger/getChargerByID" ](chargerId);
      if (!charger) {
        console.error(`Charger with ID ${chargerId} not found.`);
        return;
      }

      commit("SET_CURRENT_CHARGER", charger);
      commit("SET_CURRENT_SLOTS", charger.slots);
      commit("SET_CURRENT_POSITION", position);
      commit("SET_CURRENT_SLOT", charger.slots[ position - 1 ])

      // TODO: Check extras
      // Slot History? => found.history?
      try {
        dbHelper.Slot.listenTo(charger.slots[ position - 1 ].id,
          found => {
            commit("SET_CURRENT_SLOT", found)
          },
          error => {
            if (logIt) console.error(error);
            commit("SET_ERROR", error)
          },
        )
      } catch (error) {
        if (logIt) console.error(error);
        commit("SET_ERROR", error)
      }
    },

    async setSlotChargeMode({ commit }, { slot, mode }) {
      commit("SET_LOADING", { key: slot.id, value: true })
      try {
        await slot.setChargeMode(mode)
      } catch (err) {
        console.warn("Set Slot chargemode error :(");
        commit("SET_ERROR", err)
      } finally {
        commit("SET_LOADING", { key: slot.id, value: false })
      }
    },

    resetSlotState({ state, commit }) {
      if (state.listener)
        state.listener()
      commit("RESET_STATE");
    },

  },

  getters: {
    getCharger: (state) => state.charger,
    getSlots: (state) => state.slots,
    getSlotById: (state) => (id) =>
      state.slots.find(slot => slot.id === id),
    getSlotLoading: (state) => (id) => state.loading[ id ],
    getPosition: (state) => state.position,
    getSlotHistory: (state) => state.history,
    hasHistory: (state) => !!state.history,


    getCurrentBattery: (state, getters, rootState, rootGetters) => {
      const batteryId = getters.getCurrentSlot?.battery;
      return batteryId
        ? rootGetters[ "battery/getBatteryByID" ](batteryId)
        : null;
    },

    getCurrentLocation: (state, getters, rootState, rootGetters) => {
      const locationId = getters.getCharger?.locationId;
      return locationId
        ? rootGetters[ "location/getLocationByID" ](locationId)
        : null;
    },

    getCurrentBuilding: (state, getters, rootState, rootGetters) => {
      const buildingId = getters.getCharger?.buildingId;
      return buildingId
        ? rootGetters[ "building/getBuildingByID" ](buildingId)
        : null;
    },

    // Current
    getCurrentSlot: (state) => state.currentSlot,

    getChargemode: (state, getters) => getters.getCurrentSlot?.chargemode,
    getLastDataDate: (state, getters) => {
      let date;
      if (getters.getCurrentBattery) {
        date = getters.getCurrentBattery?.data?.ts;
      }
      if (!date) {
        date = getters.getCurrentSlot?.lastData?.ts;
      }
      return date ? new Date(date) : undefined;
    },


    getSlotNumber: (state, getters) => getters.getCurrentSlot?.slot,
    getCurrentIcurr: (state, getters) => getters.getCurrentSlot?.icurr,
    getLastData: (state, getters) => getters.getCurrentSlot?.lastData,
    getTemp: (state, getters) => getters.getCurrentSlot?.lastData?.temp,
    getVolt: (state, getters) => getters.getCurrentSlot?.lastData?.volt,
    getCurr: (state, getters) => getters.getCurrentSlot?.lastData?.curr,
    getCurrAvg: (state, getters) => getters.getCurrentSlot?.lastData?.curravg,
    getSocr: (state, getters) => getters.getCurrentSlot?.lastData?.socr,
    getSoca: (state, getters) => getters.getCurrentSlot?.lastData?.soca,
    getFccap: (state, getters) => getters.getCurrentSlot?.lastData?.fccap,
    getTtfavg: (state, getters) => getters.getCurrentSlot?.lastData?.ttfavg,
    getCyccnt: (state, getters) => getters.getCurrentSlot?.lastData?.cyccnt,

    // graphs
    getGraphsConfig: (state, getters, rootState, rootGetters) =>
      rootGetters[ "config/getGraphsConfig" ],

    getLabels: (state) => {
      if (!state.history || !state.history.steps) return [];

      // Assuming that startTime and endTime are stored as timestamps
      const startTime = state.history.start.ts;
      const endTime = state.history.end.ts;
      const totalDuration = endTime - startTime;

      return state.history.steps.map((step) => {
        const stepTime = Number(step.ts);
        if (isNaN(stepTime)) return "Error :(";
        const relativeTime = totalDuration - (endTime - stepTime);
        if (stepTime == startTime) return "Start";
        // if (stepTime == endTime) return "Ende";
        return formatTimeDifference(relativeTime);
      });
    },

    getGraphData: (state) => (dataKey) => {
      if (!state.history || !state.history.steps) return [];
      return state.history.steps.map((step) => step.data[ dataKey ]);
    },

    getYScaleBounds: (state, getters) => (dataKey) => {
      // Manual
      const graphConfig = getters.getGraphsConfig.find(
        (config) => config.data == dataKey
      );
      if (graphConfig.manual) {
        return {
          min: Number(graphConfig.min),
          max: Number(graphConfig.max),
        };
      }

      // Auto
      const dataArray = getters.getGraphData(dataKey);
      const dataMin = Math.min(...dataArray);
      const dataMax = Math.max(...dataArray);

      // Calculate a buffer, let's say 25%
      const buffer = (dataMax - dataMin) * 0.25;

      let adjustedMin = dataMin - buffer;
      let adjustedMax = dataMax + buffer;

      // Ensure the difference between adjustedMin and adjustedMax is at least 2
      if (adjustedMax - adjustedMin < 2) {
        const extraBuffer = (2 - (adjustedMax - adjustedMin)) / 2; // Divide by 2 to split between min and max
        adjustedMin -= extraBuffer;
        adjustedMax += extraBuffer;
      }

      return {
        min: adjustedMin,
        max: adjustedMax,
      };
    },

    getColor: (state, getters) => {
      let color;
      if (getters.getCurrentBattery) {
        color = "rgba(119, 0, 59, 1)";
      } else {
        color = "rgba(128, 128, 128, 1)";
      }
      return color;
    },
  },
};

function formatTimeDifference(milliseconds) {
  const diff = milliseconds / 1000;
  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;

  if (diff >= secondsInDay) {
    const days = Math.floor(diff / secondsInDay);
    const hours = Math.floor((diff % secondsInDay) / secondsInHour);
    return hours > 0 ? `${days}d, ${hours}h` : `${days}d`;
  }

  if (diff >= secondsInHour) {
    const hours = Math.floor(diff / secondsInHour);
    const minutes = Math.floor((diff % secondsInHour) / secondsInMinute);
    return minutes > 0 ? `${hours}h, ${minutes}min` : `${hours}h`;
  }

  if (diff >= secondsInMinute) {
    const minutes = Math.floor(diff / secondsInMinute);
    const seconds = Math.floor(diff % secondsInMinute);
    return seconds > 0 ? `${minutes}min, ${seconds}s` : `${minutes}min`;
  }

  return `${Math.floor(diff)}s`;
}
