<template>
  <v-dialog v-model="showDial" width="max-content">
    <v-card outlined :width="width" style="min-width: 320px">
      <v-card-title primary-title class="horizontal gapped half">
        <v-icon v-if="icon">{{ icon }}</v-icon> {{ title }}
        <v-spacer></v-spacer>
        <slot name="topactions" />
      </v-card-title>

      <v-card-subtitle
        :style="
          icon
            ? {
                'margin-left': '2rem',
              }
            : undefined
        "
      >
        {{ subtitle }}
      </v-card-subtitle>

      <v-card-text>
        <slot name="text" :item="item" />
        <slot />
      </v-card-text>
      <v-card-actions>
        <slot name="actions">
          <v-btn text @click="showDial = false">Abbruch</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-show="getDialogOnSave(dialKey)"
            outlined
            rounded
            @click="save()"
            :disabled="disableSave"
          >
            Speichern
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "BaseDialog",

  props: {
    dialKey: String,
    title: String,
    subtitle: String,
    icon: String,
    disableSave: Boolean,
    width: {
      type: String,
      default: "33vw",
    },
  },

  computed: {
    ...mapGetters("dialog", [
      "getDialogShow",
      "getDialogData",
      "getDialogOnSave",
    ]),
    showDial: {
      get() {
        return this.getDialogShow(this.dialKey);
      },
      set(value) {
        if (!value) this.hideDialog(this.dialKey);
        else
          this.showDialog({
            key: this.dialKey,
            data: this.getDialogData(this.dialKey),
            onSave: this.getDialogOnSave(this.dialKey),
          });
      },
    },
    item() {
      return this.getDialogData(this.dialKey);
    },
  },

  methods: {
    ...mapActions("dialog", ["hideDialog", "showDialog"]),

    async save() {
      await this.$emit("save", this.getDialogOnSave(this.dialKey));
      this.hideDialog(this.dialKey);
    },
  },
};
</script>

<style lang="scss" scoped></style>
